import { API_BASE_URL } from "../../../constants";
import axios from "axios";

export function shouldShowMedicationsTab(token: String) {
  return fetch(`${API_BASE_URL}/should_show_medications_tab?token=${token}`);
}

export function shouldShowPilltrackTab(token: String) {
  return fetch(`${API_BASE_URL}/should_show_pilltrack_tab?token=${token}`);
}

export function getMedicationAdherencePilltrack(token: String, days: number) {
  return fetch(
    `${API_BASE_URL}/pilltrack_adherence?token=${token}&duration=${days}`
  );
}

export function getMedicationAdherence(token: String, days: number) {
  return fetch(
    `${API_BASE_URL}/medication_adherence?token=${token}&duration=${days}`
  );
}

export function getWeeklyResults(token: String, days: number) {
  return fetch(
    `${API_BASE_URL}/medications_per_day?token=${token}&days=${days}`
  );
}

export function getPilltrackDeviceString(token: String) {
  return fetch(`${API_BASE_URL}/get_pilltrack_device_string?token=${token}`);
}

export function getPilltrackBottles(token: String) {
  return fetch(`${API_BASE_URL}/get_pilltrack_bottles?token=${token}`);
}

export function changePillTrackBottle(token: String, bottle_number: Number, medication_name?: String, schedule?: String, disabled?: Boolean) {
  const body = {
    token: token,
    bottle: bottle_number,
    medication_name: medication_name,
    schedule: schedule,
    disabled: disabled
  };
  return fetch(`${API_BASE_URL}/set_pilltrack_bottle_from_patient_portal`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}


export function getLastPutbackBottle(token: String) {
  return fetch(
    `${API_BASE_URL}/get_last_bottle_put_back?token=${token}`
  );
}

export function getLastBottleTakenOut(token: String) {
  return fetch(
    `${API_BASE_URL}/get_last_bottle_taken_out?token=${token}`
  );
}