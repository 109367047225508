import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useState } from "react";
import {
  View,
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from "react-native";
import { AthelasLineChart } from "../../../components/LineChart";
import { logout } from "../../../utils/auth";
import { Header } from "../Header";
import moment from "moment";
import { getTotalCareDashboard } from "./actions";
import { trackEvent } from "../../../utils/tracking";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { AthelasButton } from "../Components/AthelasButton";

type PropsType = {
  navigation: any;
};

export function TotalCare(props: PropsType) {
  const [fetchedDashboard, setFetchedDashboard] = useState(false);
  const [showAllTotalCareResults, setShowAllTotalCareResults] = useState(false);
  const [data, setData] = useState({
    current_weight: -1,
    onboarding: {},
    results: [],
  });

  if (!fetchedDashboard) {
    AsyncStorage.getItem("token").then((token) => {
      getTotalCareDashboard(token)
        .then((j) => {
          if (j.status !== 200) {
            logout(props.navigation);
          } else {
            return j.json();
          }
        })
        .then((res) => {
          setData(res);
        });
    });
    trackEvent("view", "viewed_total_care_tab");
    setFetchedDashboard(true);
  }

  const { onboarding, current_weight, results } = data;
  const weight_change =
    Math.round((current_weight - onboarding["start_weight"]) * 10) / 10;
  const weight_change_symbol = weight_change >= 0 ? "+" : "";

  let weights = results.map((res) => res["weight"]);
  let count = 0;
  let dates = results.map((res) => {
    count++;

    if (count == 25 || count == results.length) {
      return moment(res["test_time"]).format("MMM D, YYYY");
    } else return "";
  });
  weights = weights.reverse();
  dates = dates.reverse();

  const startBMI =
    (703 * onboarding["start_weight"]) / onboarding["height"] ** 2;
  const BMI = (703 * current_weight) / onboarding["height"] ** 2;
  const BMIdifference = BMI - startBMI;

  const BMIDescription = (BMI) => {
    if (BMI <= 18.5) {
      return "Underweight";
    } else if (BMI <= 24.9) {
      return "Healthy";
    } else if (BMI <= 29.9) {
      return "Overweight";
    } else if (BMI <= 50) {
      return "Obese";
    }
    return "  ";
  };

  return (
    <View style={styles.container}>
      <Header title="Total Care" />

      <ScrollView>
        <View style={styles.mainStatistics}>
          <View style={{ width: "50%", minWidth: 175 }}>
            <Text style={styles.statistic}>{current_weight} lbs</Text>
            <Text style={{ color: "#B9C1D9" }}>YOUR CURRENT WEIGHT</Text>
          </View>
        </View>

        {weights.length > 0 ? <AthelasLineChart labels={dates} datasets={[{ data: weights }]} /> : null}

        <View
          style={{
            padding: 24,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            <View
              style={{
                width: "33%",
                height: 60,
                textAlign: "center",
              }}
            >
              <Text style={styles.grayStatistic}>
                {onboarding["start_weight"]} lbs
              </Text>
              <Text style={styles.label}>start</Text>
            </View>
            <View
              style={{
                width: "33%",
                height: 60,
                textAlign: "center",
              }}
            >
              <Text style={styles.grayStatistic}>
                {onboarding["goal_weight"]} lbs
              </Text>
              <Text style={styles.label}>goal</Text>
            </View>
            <View
              style={{
                width: "33%",
                height: 60,
              }}
            >
              <Text style={styles.pinkStatistic}>
                {weight_change_symbol} {weight_change} lbs
              </Text>
              <Text style={styles.label}>change</Text>
            </View>
          </View>

          <View style={styles.cardContainer}>
            <View style={{ width: "60%" }}>
              <Text style={{ marginBottom: 8 }}>Your Current BMI</Text>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#E97590",
                  marginBottom: 8,
                }}
              >
                {BMIDescription(BMI)}
              </Text>
              <Text
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  color: "#9B9B9B",
                }}
              >
                {BMIdifference >= 0 ? "+" : ""}
                {Math.round(BMIdifference * 10) / 10} from your start BMI
              </Text>
            </View>
            <View
              style={{
                marginLeft: 30,
                flexDirection: "row",
                display: "flex",
                alignContent: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  color: "#E97590",
                  width: 80,
                  textAlign: "center",
                  paddingTop: 30,
                  fontSize: 16,
                  height: 80,
                }}
              >
                {Math.round(BMI * 10) / 10}
              </Text>
              <View style={{ width: 80, height: 80, marginLeft: -80 }}>
                <AnimatedCircularProgress
                  size={80}
                  width={10}
                  fill={(30 / 50) * 100}
                  tintColor="#E97590"
                  onAnimationComplete={() => console.log("onAnimationComplete")}
                  backgroundColor="#f2d3da"
                />
              </View>
            </View>
          </View>

          <Text style={styles.sectionLabel}>History</Text>
          {results.map((result, index) => {
            const weight = result["weight"];
            if (!showAllTotalCareResults && index >= 3) {
              return;
            }
            return (
              <TouchableOpacity
                style={styles.textData}
                onPress={() => {
                  props.navigation.navigate("Results", {
                    goTo: "details",
                    result_id: result.id,
                    test_type: "cardiac_care_weight_lbs",
                  })
                }}
              >
                <View style={{ flexDirection: "row", display: "flex" }}>
                  <View style={{ width: "90%" }}>
                    <Text style={{ fontWeight: "bold", fontSize: 20 }}>
                      {weight} lbs
                    </Text>
                    <Text style={{ color: "#9B9B9B", marginTop: 4 }}>
                      {moment(result["test_time"]).format(
                        "MMM D, YYYY · h:mm A"
                      )}
                    </Text>
                  </View>
                  <Text
                    style={{
                      width: "10%",
                      fontSize: 24,
                      marginTop: 8,
                      color: "#9B9B9B",
                    }}
                  >
                    ⤵
                  </Text>
                </View>
              </TouchableOpacity>
            );
          })}
          {results.length > 3 && !showAllTotalCareResults && (
            <>
              <AthelasButton
                text="View all"
                onPress={() => setShowAllTotalCareResults(true)}
              />
            </>
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  subheadline: {
    fontSize: 21,
    fontWeight: "bold",
    color: "#818181",
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    marginBottom: 16,
  },
  mainStatistics: {
    margin: 24,
    marginTop: 24,
    flexWrap: "wrap",
    flexDirection: "row",
    display: "flex",
  },
  nursePhoneContainer: {
    width: "100%",
    backgroundColor: "rgba(66, 142, 248, 255)",
    padding: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  contactNurseText: { color: "#AAA" },
  phoneNumberText: {
    textAlign: "center",
    fontWeight: "bold",
    color: "rgba(255, 255, 255, 150)",
  },
  statistic: {
    fontSize: 32,
    fontWeight: "bold",
    color: "#37313C",
    height: 40,
  },
  grayStatistic: {
    fontSize: 21,
    fontWeight: "bold",
    color: "rgba(80, 80, 80, 255)",
    height: 24,
    textAlign: "center",
  },
  pinkStatistic: {
    fontSize: 21,
    fontWeight: "bold",
    color: "#FC5378",
    height: 24,
    textAlign: "center",
  },
  textData: {
    padding: 16,
    backgroundColor: "#F8F8F6",
    width: "100%",
    marginBottom: 16,
    borderRadius: 16,
  },
  label: {
    color: "#AAA",
    textAlign: "center",
    marginBottom: 4,
  },
  sectionLabel: {
    color: "#AAA",
    textAlign: "left",
    marginBottom: 8,
  },
  cardContainer: {
    backgroundColor: "#F8F8F6",
    padding: 16,
    paddingLeft: 24,
    paddingRight: 24,
    width: "100%",
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
  },
});
