import React, { useState } from "react";
import {
    View,
    StyleSheet,
    Text,
    ScrollView,
  } from "react-native";
import { trackEvent } from "../../../utils/tracking";
import { Header } from "../Header";
import Accordion from "react-native-collapsible/Accordion";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { logout } from "../../../utils/auth";
import { getServices } from "./api"

type PropsType = {
    navigation: any;
  };

export function Account(props: PropsType) {
    const [fetchedResults, setFetchedResults] = useState(false);
    const [activeSections, setActiveSections] = useState([]);
    const [results, setResults] = useState([{service: "Empty", results: "Empty"}]);

    const _renderHeader = (section) => {
        let title = section.service;
        return (
        <View style={styles.header}>
            <Text style={styles.headerText}>{title}</Text>
        </View>
        );
    };

    const _updateSections = (activeSections) => {
        setActiveSections(activeSections);
    };

    const fetchResults = () => {
        AsyncStorage.getItem("token").then((token) => {
          if (!token) {
            return;
          }
          getServices(token)
            .then((j) => {
              if (j.status === 200) {
                return j.json();
              } else {
                logout(props.navigation);
              }
            })
            .then((res) => {
              trackEvent("view", "viewed_services");
              setResults(res);
            });
        });
        setFetchedResults(true);
      };
    
    if (!fetchedResults) {
    fetchResults();
    }
    

    return (
    
    <View style={styles.container}>
        <Header title="Account"></Header>
        <ScrollView>
            <View style={styles.banner}>
            <Text style={{ textAlign: "center", marginBottom: 4, fontSize: 30 }}>
                Athelas Services
            </Text>
            </View>

            <Accordion
            sections={results}
            activeSections={activeSections}
            renderHeader={_renderHeader}
            renderContent={(section) => (
                <View>
                <Text style={styles.answer}>{section.results}</Text>
                </View>
            )}
            onChange={_updateSections}
            />
        </ScrollView>
    </View>
    
    );
}

const styles = StyleSheet.create({
    container: {
      width: "100%",
      height: "100%",
      backgroundColor: "white",
    },
    banner: {
      backgroundColor: "#efefef",
      margin: 24,
      padding: 24,
    },
    bannerText: {
      fontSize: 18,
      textAlign: "center",
      fontWeight: "bold",
      marginBottom: 8
    },
    header: {
      padding: 16,
      paddingLeft: 24,
      paddingRight: 24,
      display: "flex",
      flexDirection: "row",
      borderTopColor: "#FF6182",
      borderTopWidth: 1,
    },
    headerText: {
      fontSize: 18,
      fontWeight: "bold",
      color: "#FF6182",
      width: "90%",
    },
    answer: {
      margin: 24,
      marginTop: 0,
      fontSize: 16,
      color: "#888",
    },
  });