import { API_BASE_URL } from "../../constants";

export function attemptLogin(phoneNumber: String) {
  return fetch(`${API_BASE_URL}/login_attempt`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      phone_number: phoneNumber,
    }),
  });
}

export function confirmLogin(phoneNumber: String, sixDigitCode: string) {
  return fetch(`${API_BASE_URL}/login_confirm`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      phone_number: phoneNumber,
      six_digit_code: sixDigitCode,
    }),
  });
}
