import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { logout } from "../../../utils/auth";
import { Header } from "../Header";
import { ActivePrograms } from "./ActivePrograms";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getProfile } from "./actions.ts";
import { RecentResults } from "./RecentResults";
import { RecentPilltrack } from "./RecentPilltrack";
import { trackEvent } from "../../../utils/tracking";
import AppBanner from '../../../components/AppBanner'
import { API_BASE_URL } from "../../../constants";
import { updatePatientDeviceMapping } from "./api"

import * as Notification from 'expo-notifications';
import { Subscription } from "expo-clipboard";
import { CurrentRenderContext } from "@react-navigation/native";
import 'react-native-get-random-values';


type PropsType = {
  navigation: any;
};

Notification.setNotificationHandler({
  handleNotification: async () => {
    return {
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true
    }
  }
});

export function Dashboard(props: PropsType) {
  const [fetchedProfile, setFetchedProfile] = useState(false);
  const [profile, setProfile] = useState({});
  const [notification, setNotification] = useState<Notification.Notification>();
  const notificationListener = useRef<Subscription>();
  const responseListener = useRef<Subscription>();
  const [token, setToken] = useState("");

  useEffect(() => {
    registerForPushNotifications();
  }, []);

  const registerForPushNotifications = async () => {
    try {
      const permission = await Notification.requestPermissionsAsync();
      if (!permission.granted) {
        return;
      };
      const token = (await Notification.getExpoPushTokenAsync()).data;
      setToken(token);
      AsyncStorage.getItem("patient_id").then(patient_id => {
        AsyncStorage.getItem("deviceID").then(deviceID => {
          if (!deviceID) {
            console.log('No Device Id');
          } else if (!token) {
            console.log('No notification token');
          } else if (deviceID.length > 0 && token.length > 0 && patient_id) {
            updatePatientDeviceMapping(token, deviceID, patient_id)
          }
        })
      })
    } catch (error) {
      console.log('Error getting push token: ', error)
    }
  }

  useEffect(() => {
    // Notifications for when app is open
    notificationListener.current = Notification.addNotificationReceivedListener(setNotification);
    // Notifications for when app is closed
    responseListener.current = Notification.addNotificationResponseReceivedListener(response => {
      trackEvent("click", "patient_clicked_notification");
    });
    return () => {
      notificationListener.current && Notification.removeNotificationSubscription(notificationListener.current);
      responseListener.current && Notification.removeNotificationSubscription(responseListener.current);
    }
  }, []);  

  //window.location is web only
  const path = window.location?.search;

  if (path && path.includes("?results=")) {
    const id = path.replaceAll("?results=", "");
    props.navigation.navigate("Results", {
    goTo: "details",
    result_id: id,
    });
  }
  if (path && path.includes("?totalCare=")) {
    props.navigation.navigate("Total Care");
  }
  if (path && path.includes("?pilltrack=")) {
    setTimeout(() =>props.navigation.navigate("PillTrack"), 400)
  }
  if (path && path.includes("?medications=")) {
    setTimeout(() =>props.navigation.navigate("Medications"), 400)
  }
  if (!fetchedProfile) {
    trackEvent("view", "viewed_dashboard");
    AsyncStorage.getItem("token").then((token) => {
      getProfile(token)
      .then((j) => {
        if (j.status === 200) {
          return j.json();
        } else {
          logout(props.navigation);
        }
      })
        .then((res) => {
          setProfile(res);
        });
    });
    setFetchedProfile(true);
  } 

  return (
    <View style={styles.container}>
      <Header/>
      <ScrollView>
        <Text style={styles.subheadline}>Welcome, {profile["first_name"]}</Text>
        <RecentPilltrack navigation = {props.navigation}/>
        <RecentResults navigation={props.navigation} />
        <ActivePrograms navigation={props.navigation} />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  subheadline: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#FE5E85",
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    marginBottom: 16,
  },
  label: {
    color: "#FE5E85",
    fontSize: 13,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 4,
    fontWeight: "bold",
    marginTop: 16,
  },
  labelDisabled: {
    color: "#888",
    fontSize: 13,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 4,
    fontWeight: "bold",
    marginTop: 16,
  },
  textInput: {
    borderWidth: 2,
    borderColor: "#FE5E85",
    borderStyle: "solid",
    borderRadius: 4,
    padding: 8,
    marginLeft: 24,
    marginRight: 24,
    color: "#FE5E85",
  },
  textInputDisabled: {
    borderWidth: 2,
    borderColor: "#888",
    borderStyle: "solid",
    borderRadius: 4,
    padding: 8,
    marginLeft: 24,
    marginRight: 24,
    color: "#888",
  },
  loginButtonContainer: {
    backgroundColor: "#FE5E85",
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 16,
    marginTop: 24,
    marginRight: 24,
    marginLeft: 24,
  },
  logoutButtonContainer: {
    backgroundColor: "#eb4034",
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 16,
    marginTop: 24,
    marginRight: 24,
    marginLeft: 24,
  },
  loginButtonText: { color: "white", fontWeight: "bold", textAlign: "center" },
});
