import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Platform,
    ScrollView,
    Dimensions,
    Linking
  } from "react-native";
  import React, { useState } from "react";
  import {
    isAndroid,
    isIOS
  } from "react-device-detect";
  import { Entypo } from '@expo/vector-icons'; 
  import { trackEvent } from "../utils/tracking";

export default function AppBanner() {
    const [displayBanner, toggleBanner] = useState(true);
    return (
    (Platform.OS === 'web' && displayBanner ) ?
    <TouchableOpacity
      style = {{width: '100%'}}
      onPress = {() =>{
        if (isIOS) {
          trackEvent("click", "opened_ios_app");
          Linking.openURL('https://apps.apple.com/us/app/athelas/id1610811347?platform=iphone')
        } else if (isAndroid) {
          trackEvent("click", "opened_android_app");
          Linking.openURL('https://play.google.com/store/apps/details?id=com.athelas.patientportal')
        } else {
          trackEvent("click", "opened_other_app");
          Linking.openURL('https://apps.apple.com/us/app/athelas/id1610811347?platform=iphone')
        }
      }
      }
    >
        <View style = {styles.appBanner}>
        <Text style = {styles.bannerText}>Download our app for a better experience!</Text>
        <TouchableOpacity style = {{position: 'absolute', right: 10, top: 10}}
            onPress = {() => toggleBanner(false)}
        >
        <Entypo name="cross" size={24} color="white" />
        </TouchableOpacity>
        </View>
    </TouchableOpacity> : null 
    ) 
}

const styles = StyleSheet.create({
    appBanner: {
        flex: 1, 
        backgroundColor: '#FE5E85', 
        width: '100%', 
      },
      bannerText: {
        fontSize: 30,
        color: 'white',
        textAlign: 'center',
        margin: 20,
        fontWeight: '500'
      },
})