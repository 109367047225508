import AsyncStorage from "@react-native-async-storage/async-storage";
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import React, { useState, useEffect, useRef } from "react";
import {
  getMedicationAdherencePilltrack,
  getWeeklyResults,
  shouldShowPilltrackTab,
} from "./api";
import { RefreshControl, Platform, View, ScrollView, Text, StyleSheet, TouchableOpacity } from "react-native";
import { logout } from "../../../utils/auth";
import { Header } from "../Header";
import moment, {Moment} from "moment";
import { trackEvent } from "../../../utils/tracking";
import { PillTrackOnboarding } from "../Dashboard/PillTrackOnboarding";
import { Ionicons } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'; 
import { Entypo } from '@expo/vector-icons'; 
import {Calendar, CalendarList, Agenda} from 'react-native-calendars';
import { PilltrackAdherence } from "./PilltrackAdherence";
import { getProfile } from "../Dashboard/actions";
import { PilltrackEdit } from "./PilltrackEdit";


import {
  useFonts,
  Manrope_400Regular,
  Manrope_500Medium,
  Manrope_700Bold,
} from "@expo-google-fonts/manrope";

type PropsType = {navigation: any;};

type CalendarDate = {
  index: number,
  dateString: string,
  selectedDate: {calendarCurrentDate: {selected: Boolean}}
};

const wait = (timeout) => {
  return new Promise(resolve => setTimeout(resolve, timeout));
}

export function Pilltrack(props: PropsType) {
  let [fontsLoaded] = useFonts({
    Manrope_400Regular,
    Manrope_500Medium,
    Manrope_700Bold,
  });

  const [hasOnboarded, setHasOnboarded] = useState(true);
  const [data, setData] = useState([]);
  const [totalMonthlyTaken, setTotalMonthlyTaken] = useState(0);
  const [totalMonthlyDoses, setTotalMonthlyDoses] = useState(0);
  const [totalDailyTaken, setTotalDailyTaken] = useState(0);
  const [totalDailyDoses, setTotalDailyDoses] = useState(0);
  const [fetchedProfile, setFetchedProfile] = useState(false);
  const [profile, setProfile] = useState({});
  const [mode, setMode] = useState('main'); //main, adherence, edit
  const [vacationMode, setVacationMode] = useState(false);

  const fetchProfile = () => {
    AsyncStorage.getItem("token").then((token) => {
      getMedicationAdherencePilltrack(token, 28)
        .then((j) => j.json())
        .then((res) => {
          setTotalMonthlyDoses(res["total_doses"]);
          setData(res["med_results"]);
          setTotalMonthlyTaken(res["total_taken_count"]);
        });
      getMedicationAdherencePilltrack(token, 1)
        .then((j) => j.json())
        .then((res) => {
          setTotalDailyDoses(res["total_doses"]);
          setTotalDailyTaken(res["total_taken_count"]);
        });
      shouldShowPilltrackTab(token)
        .then((j) => j.json())
        .then((res) => {
          setHasOnboarded(res["has_onboarded_pilltrack"]);
        });
      getProfile(token)
        .then((j) => j.json())
        .then((res) => {
          setProfile(res);
        });
    });
    trackEvent("view", "viewed_pilltrack_tab");
    setFetchedProfile(true);
  }

  if (!fetchedProfile) {
    fetchProfile()
  };

  //   AsyncStorage.getItem("token").then((token) => {
  //     getProfile(token)
  //       .then((j) => j.json())
  //       .then((res) => {
  //         setProfile(res);
  //       });
  //   });
  //   setFetchedProfile(true);
  // }

  const currentDate = moment().format("dddd, MMMM Do");

  const [refreshing, setRefreshing] = React.useState(false);
  
    const onRefresh = React.useCallback(() => {
      setRefreshing(true);
      wait(2000).then(() => setRefreshing(false));
    }, []);

  let monthlyAdherence = 0;
  if (totalMonthlyDoses > 0) {
    monthlyAdherence = Math.round((totalMonthlyTaken / totalMonthlyDoses) * 100);
  }

  let dailyAdherence = 0;
  if (totalDailyDoses > 0) {
    dailyAdherence = Math.round((totalDailyTaken / totalDailyDoses) * 100);
  }
  if (!hasOnboarded) {
    return (
      <View>
        <Header title="Pilltrack" />
        <View style={{ marginTop: 24 }}>
        <PillTrackOnboarding />
        </View>
      </View>
    );
  }


  const mainPilltrack = () => {
    return(
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }
      >
      <View style = {styles.innerContainer}>
        <Text style={styles.title}>Hello, {profile["first_name"]}</Text>
        <Text style = {styles.title}>Welcome to Athelas Pilltrack!</Text>
        <View style = {styles.card}>
          <Text style = {styles.subtext}>General Performance</Text>
          <View style = {[styles.row,{alignItems: 'center'}]}>
            <View style = {styles.circleContainer}>
            <AnimatedCircularProgress 
              size={120} 
              width={10} 
              fill = {Math.min(monthlyAdherence, 100)}
              backgroundColor = "#EFF5FF"
              tintColor = "#7DB8EF"
              rotation = {0}
              lineCap = "round"
              >
                {() => (<View>
                  <AnimatedCircularProgress 
                    size={90} 
                    width={10} 
                    fill = {Math.min(dailyAdherence, 100)}
                    backgroundColor = "#FAEDF4"
                    tintColor = "#EB717C"
                    rotation = {0}
                    lineCap = "round"
                    ></AnimatedCircularProgress>
              </View>)}
              </AnimatedCircularProgress>
            </View>
            <View style = {styles.labelContainer}>
              <View style = {styles.row}>
                <View style = {[styles.dot,{backgroundColor: '#7DB8EF'}]}/>
                <View>
                  <Text style ={styles.percentage}>{monthlyAdherence}%</Text>
                  <Text style = {styles.subtext}>Monthly Adherence</Text>
                </View>
              </View>
              <View style = {styles.row}>
                <View style = {[styles.dot,{backgroundColor: '#EB717C'}]}/>
                <View>
                  <Text style ={styles.percentage}>{dailyAdherence}%</Text>
                  <Text style = {styles.subtext}>Daily Adherence</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Text style = {styles.title}>I want to:</Text>
        <TouchableOpacity
          onPress={() => {setMode('adherence')}}
        >
        <View style = {styles.card2}>
          <View style = {[styles.row, {alignItems: 'center'}]}>
            <View style = {styles.redIconContainer}>
              <Entypo name="calendar" size={24} color="#EB717C" />
            </View>
            <Text style = {styles.subtitle}>View Monthly Adherence</Text>
            <View style = {styles.arrow}>
              <AntDesign name="right" size={18} color="#453D3F" />
            </View>
          </View>
        </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {setMode('edit')}}
        >
        <View style = {styles.card2}>
          <View style = {[styles.row, {alignItems: 'center'}]}>
            <View style = {styles.blueIconContainer}>
              <MaterialCommunityIcons name="pill" size={24} color="#4DB4FF" />
            </View>
            <Text style = {styles.subtitle}>Edit my medications</Text>
            <View style = {styles.arrow}>
              <AntDesign name="right" size={18} color="#453D3F" />
            </View>
          </View>
        </View>
        </TouchableOpacity>
      </View>
      </ScrollView>
    )
  }

  const adherencePilltrack = () => {
    return(
      <ScrollView style = {{backgroundColor: 'white'}}>
       <PilltrackAdherence setMode = {setMode}/>
      </ScrollView>
    )
  }

  const editPilltrack = () => {
    return(
      <ScrollView style = {{backgroundColor: 'white'}}>
        <PilltrackEdit setMode = {setMode} fetchProfile = {fetchProfile}/>
      </ScrollView>
    )
  }

  return (
    <View style={styles.container}>
    <Header title="Pilltrack" />
        {mode === 'main' ? mainPilltrack(): null}
        {mode === 'adherence' ? adherencePilltrack(): null}
        {mode === 'edit' ? editPilltrack(): null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  innerContainer: {
    width: '85%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 16,
    marginBottom: 32
  },
  scroll: {
    flexGrow: 1,
    alignItems: "center",
    textAlign: "right",
    marginTop: 24,
  },
  title: {
    fontSize: 18,
    fontFamily: "Manrope_400Regular",
    color: "#453D3F",
    fontWeight: "bold",
    marginTop: 16
  },
  subtitle: {
    fontSize: 16,
    fontFamily: "Manrope_400Regular",
    color: "#453D3F",
  },
  dateRange: {
    fontFamily: "Manrope_400Regular",
    color: "#453D3F",
    fontSize: 16,
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 16,
    shadowColor: 'grey',
    shadowOpacity: 0.2,
    shadowRadius: 8,
    paddingTop: 12,
    paddingBottom: 18,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 16,
    elevation: 4
  },
  card2: {
    backgroundColor: 'white',
    borderRadius: 8,
    shadowColor: 'grey',
    shadowOpacity: 0.2,
    shadowRadius: 8,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 18,
    paddingRight: 18,
    marginTop: 16,
    elevation: 4
  },
  row: {
    flexDirection: 'row',
    // alignItems: 'center',
  },
  subtext: {
    fontSize: 14,
    color: "#453D3F",
    fontFamily: 'Manrope_400Regular'
  },
  adherence: {
    fontSize: 16,
    color: '#453D3F',
    fontFamily: "Manrope_700Bold",
    fontWeight: 'bold',
  },
  percentage: {
    fontSize: 24,
    fontFamily: 'Manrope_400Regular'

  },
  greyIconContainer: {
    backgroundColor: '#e6e6e6',
    padding: 8,
    borderRadius: 8,
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12
    
},
  redIconContainer: {
    backgroundColor: '#FAEDF4',
    padding: 8,
    borderRadius: 8,
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12
  },
  blueIconContainer: {
    backgroundColor: '#E1F1FF',
    padding: 8,
    borderRadius: 8,
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12
  },
  arrow: {
    position: 'absolute',
    right: 0
  },
  circleContainer: {
    marginTop: 12,
  },
  labelContainer: {
    marginLeft: 24
  },
  dot: {
    // backgroundColor: '#7DB8EF', 
    height: 15, 
    width: 15, 
    borderRadius: 1000,
    marginTop: 10,
    marginRight: 12
  }
});
