import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useState } from "react";
import {
  Platform,
  Button,
  Dimensions,
  TouchableOpacity,
  Alert,
} from "react-native";
import { Text, View, StyleSheet, Image } from "react-native";
import { deleteResult, getResultDetails } from "./actions";
import moment from "moment";

import Glucose from "../Dashboard/assets/glucose.png";
import AthelasOne from "../Dashboard/assets/athelas_one.png";
import Weight from "../Dashboard/assets/weight.png";
import HeartRate from "../Dashboard/assets/heart_rate.png";
import { trackEvent } from "../../../utils/tracking";

const TEST_TO_IMAGE_MAP = {
  BLOOD_PRESSURE: HeartRate,
  CARDIAC_CARE: Weight,
  WEIGHT: Weight,
  HEART_RATE: HeartRate,
  GLUCOSE: Glucose,
};

type PropsType = {
  result_id: number;
  navigation: any;
  setMode: any;
  fetchResults: any;
};

export function ResultDetails(props: PropsType) {
  const [fetchedDetails, setFetchedDetails] = useState(-1);
  const [resultDetails, setResultDetails] = useState({});

  if (fetchedDetails !== props.result_id) {
    AsyncStorage.getItem("token").then((token) => {
      getResultDetails(token, props.result_id)
        .then((j) => j.json())
        .then((res) => {
          setResultDetails(res);
        });
    });
    setFetchedDetails(props.result_id);
  }

  const triggerResultDeletion = (result_id) => {
    Platform.OS === "ios" || Platform.OS === "android"
      ? Alert.alert("Are you sure you want to delete this result?", "", [
          {
            text: "Cancel",
            onPress: () => console.log("Cancel Pressed"),
            style: "cancel",
          },
          {
            text: "Yes",
            onPress: () => {
              console.log("OK Pressed");
              AsyncStorage.getItem("token").then((token) => {
                deleteResult(token, props.result_id).then((res) => {
                  props.setMode("history");
                  props.fetchResults();
                });
              });
            },
          },
        ])
      : (() => {
          if (confirm("Are you sure you want to delete this result?")) {
            AsyncStorage.getItem("token").then((token) => {
              deleteResult(token, props.result_id).then((res) => {
                window.location = window.location.origin;
              });
            });
          }
        })();
  };

  return (
    <View>
      <View style={styles.mainContainer}>
        <View style={{ paddingTop: 36, paddingBottom: 36, width: "40%" }}>
          <View style={{ flexDirection: "row", display: "flex" }}>
            {/* Total Care */}
            {resultDetails["cardiac_care_weight_lbs"] ? (
              <Text style={styles.statistic}>
                {resultDetails["cardiac_care_weight_lbs"]} lbs
              </Text>
            ) : null}

            {/* Blood Pressure */}
            {resultDetails["systolic"] ? (
              <Text style={styles.statistic}>
                {resultDetails["systolic"]} sys
              </Text>
            ) : null}
            {resultDetails["diastolic"] ? (
              <Text style={styles.statistic}>
                {resultDetails["diastolic"]} dia
              </Text>
            ) : null}

            {/* Heart Rate */}
            {resultDetails["bpm"] ? (
              <Text style={styles.statistic}>{resultDetails["bpm"]} bpm</Text>
            ) : null}

            {/* Weight */}
            {resultDetails["weight"] ? (
              <Text style={styles.statistic}>
                {resultDetails["weight"]} lbs
              </Text>
            ) : null}
            {/* Glucose */}
            {resultDetails["glucose_value"] ? (
              <Text style={styles.statistic}>
                {resultDetails["glucose_value"]} mg/dL
              </Text>
            ) : null}
          </View>
          {/* <hr></hr> */}

          <Text style={styles.caption}>
            {resultDetails.test_type?.replace(/_/g, "")}
          </Text>
        </View>

        <Image
          source={TEST_TO_IMAGE_MAP[resultDetails["test_type"]]}
          style={{ width: "50%", height: "100%" }}
          resizeMode="contain"
        />
      </View>

      {resultDetails["percentage_change_from_previous_result"] ? (
        <View
          style={{
            flexDirection: "row",
            display: "flex",
            width: Dimensions.get("window").width - 24 * 2,
            margin: 24,
          }}
        >
          <Text
            style={{
              fontSize: 24,
              fontWeight: "bold",
              width: "25%",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            {resultDetails["percentage_change_from_previous_result"]}%
          </Text>
          <Text style={{ fontSize: 16, color: "#888", width: "75%" }}>
            Percentage change from your previous result.
          </Text>
        </View>
      ) : null}

      <Text
        style={{
          color: "#888",
          fontSize: 13,
          marginLeft: 24,
          marginBottom: 4,
          marginTop: 24,
        }}
      >
        TEST RECORDED AT:
      </Text>
      <View style={styles.timeContainer}>
        <Text style={styles.timeText}>
          {moment(resultDetails["created_at"]).format("MMM D, YYYY - hh:mm A")}
        </Text>
      </View>

      {resultDetails.data?.note ? (
        <>
          <Text
            style={{
              color: "#888",
              fontSize: 13,
              marginLeft: 24,
              marginBottom: 4,
              marginTop: 24,
              textTransform: "uppercase",
            }}
          >
            miscellaneous notes:
          </Text>
          <View style={styles.timeContainer}>
            <Text style={styles.timeText}>{resultDetails.data?.note}</Text>
          </View>
        </>
      ) : null}
      <TouchableOpacity
        style={{
          width: 200,
          marginLeft: (Dimensions.get("window").width - 200) / 2,
        }}
        onPress={() => {
          triggerResultDeletion(props.result_id);
          trackEvent("click", "deleted_result");
        }}
      >
        <Text
          style={{
            fontWeight: "bold",
            textAlign: "center",
            color: "#ff8293",
            marginTop: 36,
          }}
        >
          DELETE RESULT
        </Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 16,
  },
  timeContainer: {
    padding: 8,
    borderWidth: 2,
    borderColor: "#888",
    borderStyle: "solid",
    margin: 24,
    marginTop: 0,
  },
  timeText: {
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: 17,
    color: "#888",
  },
  statistic: {
    fontSize: 26,
    fontWeight: "bold",
    marginBottom: 4,
    marginRight: 16,
    textTransform: "uppercase",
    paddingBottom: 0,
    width: "60%",
  },
  mainContainer: {
    width: Dimensions.get("window").width - 48,
    marginLeft: 24,
    marginRight: 24,
    marginTop: 24,
    paddingLeft: 24,
    paddingRight: 0,
    backgroundColor: "#EFEFEF",
    flexDirection: "row",
    display: "flex",
  },
  caption: {
    fontSize: 18,
    color: "#444",
    marginTop: -8,
    paddingTop: 0,
  },
});
