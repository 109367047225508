import React from "react";
import { Platform, View, Text, StyleSheet } from "react-native";

type PropsType = {};

export function EmptyResultsModal(props: PropsType) {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>No Results Found</Text>
      <Text style={{ marginTop: 8 }}>
        Historical diagnostics data will appear here after you use your smart
        devices to take measurements (i.e. weight scale, blood pressure cuff,
        glucometer, etc).
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    padding: 16,
    marginLeft: 24,
    marginRight: 24,
    shadowColor: "#BFBFBF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
    elevation: (Platform.OS === 'ios' || Platform.OS === 'android')  ? 20 : "inherit",
    marginBottom: 24,
    marginTop: 24,
  },
  text: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#6ABFC0",
  },
});
