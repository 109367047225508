import React, { useEffect } from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { View, Platform } from "react-native";
import { ResultsTab } from "./Results/Results";
import { Settings } from "./Settings/Settings";
import { Dashboard } from "./Dashboard/Dashboard";
import { FAQ } from "./FAQ/FAQ";
import {Account} from "./Account/Copay"
import { TotalCare } from "./TotalCare/TotalCare";
import { Medications } from "./Medications/Medications";
import { Pilltrack } from "./Medications/Pilltrack";
import { useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { showTotalCareDashboard } from "./TotalCare/actions";
import { shouldShowMedicationsTab, shouldShowPilltrackTab } from "./Medications/api";
import * as Application from 'expo-application';
import { useFeatureFlag } from "../../utils/feature_flag";
import { shouldShowServicesTab } from "./Account/api";

type PropsType = {};
const Tab = createBottomTabNavigator();

export function Main(props: PropsType) {
  const [fetchedTabs, setFetchedTabs] = useState(false);
  const [showTotalCare, setShowTotalCare] = useState(false);
  const [showMedicationsTab, setShowMedicationsTab] = useState(false);
  const [showPilltrackTab, setShowPilltrackTab] = useState(false);
  const [showServicesTab, setShowServicesTab] = useState(false);

  if (!fetchedTabs) {
    AsyncStorage.getItem("token").then((token) => {
      showTotalCareDashboard(token)
        .then((j) => j.json())
        .then((res) => {
          setShowTotalCare(res["display"]);
        });
      shouldShowPilltrackTab(token)
        .then((j) => j.json())
        .then((res) => {
          setShowPilltrackTab(res["show_pilltrack_tab"]);
        });
      shouldShowMedicationsTab(token)
        .then((j) => j.json())
        .then((res) => {
          setShowMedicationsTab(res["show_medications_tab"]);
        });
      shouldShowServicesTab(token)
      .then((j) => j.json())
      .then((res) => {
        setShowServicesTab(res['show_services_tab'])
      });
    });

    setFetchedTabs(true);
  }

  const getDeviceId = async () => {
    if (Platform.OS === 'android') {
      let androidID = Application.androidId;
      AsyncStorage.getItem("deviceID").then(deviceID => {
        if (!deviceID) {
          AsyncStorage.setItem("deviceID", androidID);
        }
      });
      return Application.androidId;
    } else if (Platform.OS === 'ios') {
      let iosID = await Application.getIosIdForVendorAsync();
      AsyncStorage.getItem("deviceID").then(deviceID => {
        if (!deviceID && iosID) {
          AsyncStorage.setItem("deviceID", iosID);
        }
      })
    }
  }
  getDeviceId();

  return (
    <Tab.Navigator
      tabBarOptions={{
        activeTintColor: "#e91e63",
        tabStyle: {
          padding: 4,
        },
      }}
    >
      <Tab.Screen
        name="Dashboard"
        component={Dashboard}
        options={{
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons name="home" color={color} size={size} />
          ),
        }}
      />
      <Tab.Screen
        name="Results"
        component={ResultsTab}
        options={{
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons
              name="chart-timeline-variant"
              color={color}
              size={size}
            />
          ),
        }}
      />
      {showPilltrackTab ? 
        <Tab.Screen
          name="PillTrack"
          component={Pilltrack}
          options={{
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons name="pill" color={color} size={size} />
            ),
          }}
        /> : null
      }
      {(!showPilltrackTab && showMedicationsTab) ?
        <Tab.Screen
          name="Medications"
          component={Medications}
          options={{
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons name="pill" color={color} size={size} />
            ),
          }}
        /> : null
      }
      {showTotalCare ? (
        <Tab.Screen
          name="Total Care"
          component={TotalCare}
          options={{
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons
                name="bullseye"
                color={color}
                size={size}
              />
            ),
          }}
        />
      ) : null}
      <Tab.Screen
        name="Settings"
        component={Settings}
        options={{
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons
              name="apple-keyboard-command"
              color={color}
              size={size}
            />
          ),
        }}
      />
      <Tab.Screen
        name="FAQ"
        component={FAQ}
        options={{
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons
              name="help-circle-outline"
              color={color}
              size={size}
            />
          ),
        }}
      />
      {showServicesTab && 
        <Tab.Screen
          name="Services"
          component={Account}
          options={{
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons
                name="account"
                color={color}
                size={size}
              />
            ),
          }}
        />
      }
    </Tab.Navigator>
  );
}
