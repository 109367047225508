import React, { Component } from "react";
import {
  Platform,
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
} from "react-native";
import { AthelasLineChart } from "../../../components/LineChart";
import moment from "moment";
import { AddNewTestResult } from "./AddNewTestResult";

type PropsType = {
  section: any;
  setMode: any;
  setResultID: any;
  navigation: any;
};
type StateType = {
  numEntries: number;
};

export const DATA_MAP = {
  HEART_RATE: ["bpm"],
  BLOOD_PRESSURE: ["systolic", "diastolic"],
  WEIGHT: ["weight"],
  CARDIAC_CARE: ["cardiac_care_weight_lbs"],
  GLUCOSE: ["glucose_value"],
  PULSE_OXIMETRY: ["spo2"],
};
export class ResultsSummary extends Component<PropsType, StateType> {
  constructor(props) {
    super(props);

    this.state = {
      numEntries: 2,
    };
  }

  generateLabels(content) {
    return content
      .map((result, index) => {
        if (index == 0 || index == content.length - 1) {
          return moment(result.created_at).format("M/D/YY");
        } else {
          return "";
        }
      })
      .reverse();
  }

  generateDatasets(content) {
    const test_type = content[0].test_type;
    const attributes = DATA_MAP[test_type];

    return attributes.map((attr) => {
      return {
        data: content
          .map((result) => {
            return result[attr];
          })
          .reverse(),
        color: (data) => {
          return `rgba(66, 142, 248, ${data})`;
        },
      };
    });
  }

  alertDescription = (test_type) => {
    const content = {
      WEIGHT: "This weight result is recorded in pounds (lbs).",
      GLUCOSE:
        "Glucose values determine the level of glucose (sugar) in a person's blood.",
      HEART_RATE:
        "BPM is a common measuring standard for heart rate. It stands for Beats Per Minute",
      BLOOD_PRESSURE:
        "'dia' is short for diastolic (or the phase of your heartbeat when the heart muscle relaxes. 'sys' stands for systolic or the pressure of your arteries when your heart beats.",
      CARDIAC_CARE:
        "The total care program measures your weight in pounds (lbs).",
      PULSE_OXIMETRY:
        "The measures how much oxygen is in someone's blood (spo2)",
    };
    alert(content[test_type]);
  };

  render() {
    const test_type = this.props.section.content[0].test_type;
    const hasMoreEntries =
      this.props.section.content.length > this.state.numEntries;
    const numMoreEntries =
      this.props.section.content.length - this.state.numEntries;
    return (
      <>
        {Object.keys(DATA_MAP).includes(test_type) &&
          this.props.section.content?.length > 1 && (
            <AthelasLineChart
              key={test_type}
              labels={this.generateLabels(this.props.section.content)}
              datasets={this.generateDatasets(this.props.section.content)}
            />
          )}

        <AddNewTestResult test_type={test_type} setMode={this.props.setMode} />

        <View style={styles.contentContainer}>
          {this.props.section.content
            .slice(0, this.state.numEntries)
            .map((data: any) => {
              return (
                <TouchableOpacity
                  style={{
                    backgroundColor: "#EFEFEF",
                    borderRadius: 4,
                    width: "100%",
                    marginBottom: 16,
                    padding: 16,
                  }}
                  onPress={() => {
                    this.props.setMode("details");
                    this.props.setResultID(data.id);
                    this.props.navigation.navigate("Results", {
                      goTo: "details",
                      result_id: data.id,
                    });
                  }}
                >
                  <View style={{ flexDirection: "row", display: "flex" }}>
                    <View style={{ width: "90%" }}>
                      <View style={{ flexDirection: "row", display: "flex" }}>
                        {/* Total Care */}
                        {data["cardiac_care_weight_lbs"] && (
                          <Text style={styles.statistic}>
                            {data["cardiac_care_weight_lbs"]} lbs
                          </Text>
                        )}

                        {/* Blood Pressure */}
                        {data["diastolic"] && (
                          <Text style={styles.statistic}>
                            {data["diastolic"]} dia
                          </Text>
                        )}
                        {data["systolic"] && (
                          <Text style={styles.statistic}>
                            {data["systolic"]} sys
                          </Text>
                        )}

                        {/* Heart Rate */}
                        {data["bpm"] && (
                          <Text style={styles.statistic}>
                            {data["bpm"]} bpm
                          </Text>
                        )}

                        {/* Weight */}
                        {data["weight"] && (
                          <Text style={styles.statistic}>
                            {data["weight"]} lbs
                          </Text>
                        )}

                        {/* Glucose */}
                        {data["glucose_value"] && (
                          <Text style={styles.statistic}>
                            {data["glucose_value"]} mg/dL
                          </Text>
                        )}
                        {/* Pulse Oximetry */}
                        {data["spo2"] && (
                          <Text style={styles.statistic}>
                            {data["spo2"]} % SpO2
                          </Text>
                        )}
                      </View>
                      <Text style={{ fontSize: 13 }}>
                        {moment(data.created_at)
                          .utc()
                          .local()
                          .format("MMM D, YYYY - hh:mm A")}
                      </Text>
                    </View>
                    <TouchableOpacity
                      onPress={() => this.alertDescription(data["test_type"])}
                    >
                      <Text
                        style={{
                          fontSize: 24,
                          color: "#AAA",
                          fontWeight: "bold",
                          marginTop: 4,
                        }}
                      >
                        ⓘ
                      </Text>
                    </TouchableOpacity>
                  </View>
                </TouchableOpacity>
              );
            })}
          {hasMoreEntries && (
            <TouchableOpacity
              onPress={() => {
                this.setState({
                  numEntries: this.props.section.content.length,
                });
              }}
            >
              <Text style={{ color: "#A4A4A4", fontWeight: "bold" }}>
                Tap to view {numMoreEntries} more test results →
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 16,
  },
  statistic: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
    marginRight: 16,
  },
});
