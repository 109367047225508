import React, { useState } from "react";
import { Platform, View, StyleSheet, Text, TouchableOpacity, Image, Linking } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import MedicationImage from "./assets/medication.png";
import { getProfile } from "./actions.ts";
import { trackEvent } from "../../../utils/tracking";
import { shouldShowPilltrackTab } from "../Medications/api";

type PropsType = {};

export function MedicationOnboarding(props: PropsType) {
  const [fetchedProfile, setFetchedProfile] = useState(false);
  const [profile, setProfile] = useState({});

  const [fetchedPillTrackStatus, setFetchedPillTrackStatus] = useState(false);
  const [showPilltrackModal, setShowPilltrackOnboardingModal] =
    useState(false);
  const [pilltrackOnboardingLink, setPilltrackOnboardingLink] = useState('')

  if (!fetchedPillTrackStatus) {
    AsyncStorage.getItem("token").then((token) => {
      shouldShowPilltrackTab(token)
        .then((j) => j.json())
        .then((res) => {
          setShowPilltrackOnboardingModal(!res["has_onboarded_gem"]);
          setPilltrackOnboardingLink(res['gem_onboarding_link'])
        });
      setFetchedPillTrackStatus(true);
    });
  }

  if (!fetchedProfile) {
    AsyncStorage.getItem("token").then((token) => {
      getProfile(token)
        .then((j) => j.json())
        .then((res) => {
          setProfile(res);
        });
    });
    setFetchedProfile(true);
  }

  if (!showPilltrackModal) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", display: "flex" }}>
        <View style={{ width: "100%" }}>
          <Image
            source={MedicationImage}
            style={{
              width: "60%",
              height: 150,
              marginLeft: "20%",
              marginBottom: 8,
            }}
            resizeMode="contain"
          />
          <View style={{ flexDirection: "row", display: "flex", textAlign: "center" }}>
            <Text style={styles.text}>
              {"Did you get your PillTrack in the mail? Let's set it up!"}
            </Text>
          </View>
          <TouchableOpacity
            onPress={() => {
              trackEvent(
                "click",
                "clicked_medication_onboarding_from_dashboard"
              );
              (Platform.OS === 'ios' || Platform.OS === 'android')  ? Linking.openURL(pilltrackOnboardingLink) :
              window.open(
                pilltrackOnboardingLink,
                "_blank"
              );
            }}
          >
            <View style={styles.loginButtonContainer}>
              <Text style={styles.loginButtonText}>GET STARTED</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    padding: 16,
    marginLeft: 24,
    marginRight: 24,
    shadowColor: "#BFBFBF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
    elevation: (Platform.OS === 'ios' || Platform.OS === 'android')  ? 20 : "inherit",
    marginBottom: 24,
  },
  text: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#6ABFC0",
  },
  loginButtonContainer: {
    backgroundColor: "#6ABFC0",
    padding: 8,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 4,
    marginTop: 16,
  },
  loginButtonText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
});
