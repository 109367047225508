import axios from "axios";
import { API_BASE_URL } from "../../../constants";

export type Payer = {
  payer_id: string;
  payer_name: string;
};

type PayerSearchResult = {
  payers: Payer[];
};

export type InsuranceProfile = {
  payer_name: string;
  payer_id: string;
  policy_number: string;
};

export type Profile = {
  first_name: string;
  last_name: string;
  phone: string;
  address: {};
  points: string;
  onboardingToken: string;
  payer_name: string;
  payer_id: string;
  policy_number: string;
};

export const queryPayers = (searchTerm: string) => {
  return axios.get<PayerSearchResult>(
    `https://api.athelas.com/insurance/search_insurance_by_name/${searchTerm}`
  );
};

export const updateInsuranceInfo = (
  token: string,
  newInsuranceProfile: InsuranceProfile
) => {
  const body = {
    token: token,
    payer_name: newInsuranceProfile.payer_name,
    payer_id: newInsuranceProfile.payer_id,
    policy_number: newInsuranceProfile.policy_number,
  };
  return axios.post(
    `${API_BASE_URL}/update_patient_insurance_info`,
    JSON.stringify(body),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};
