import {
  View,
  Text,
  StyleSheet,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React from "react";

import DownArrow from "./assets/down-arrow.svg";
import { Header } from "../Header";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getResults } from "./actions";
import * as FileSystem from "expo-file-system";
import { shareAsync } from "expo-sharing";
import { Platform } from "react-native";
import Accordion from "react-native-collapsible/Accordion";
import { useState } from "react";
import { ResultsSummary } from "./ResultsSummary";
import { logout } from "../../../utils/auth";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { AddNewTestResultForm } from "./AddNewTestResultForm";
import { ResultDetails } from "./ResultDetails";
import { trackEvent } from "../../../utils/tracking";
import { EmptyResultsModal } from "./EmptyResultsModal";

export function ResultsTab({ route, navigation }) {
  const [fetchedResults, setFetchedResults] = useState(false);
  const [activeSections, setActiveSections] = useState([]);
  const [results, setResults] = useState({});
  const [mode, setMode] = useState("history"); // history, details, or one of the test_types
  const [fetchedTestType, setFetchedTestType] = useState(false);
  const [resultID, setResultID] = useState(-1);

  const [lastMode, setLastMode] = useState(null);

  if (route.params?.goTo) {
    const { goTo, test_type, result_id } = route.params;
    if (fetchedTestType !== test_type || result_id !== resultID) {
      if (goTo === "add_new") {
        setMode(test_type);
      } else if (goTo == "details") {
        setMode("details");
        setResultID(result_id);
      }
      setFetchedTestType(test_type);
      setLastMode(goTo);
    }
  }

  const fetchResults = () => {
    AsyncStorage.getItem("token").then((token) => {
      if (!token) {
        return;
      }
      getResults(token)
        .then((j) => {
          if (j.status === 200) {
            return j.json();
          } else {
            logout(navigation);
          }
        })
        .then((res) => {
          let resultsMap = res
          trackEvent("view", "viewed_results");
          // arrange test results by testing time
          Object.keys(resultsMap).forEach((testType) => {
            resultsMap[testType].sort((a, b) => {
              return new Date(b.test_time).getTime() - new Date(a.test_time).getTime();
            });
          });

          setResults(resultsMap);
        });
    });
    setFetchedResults(true);
  };

  if (!fetchedResults) {
    fetchResults();
  }

  const _renderHeader = (section) => {
    let title = section.title.replace("_", " ").toUpperCase();
    if (title === "CARDIAC CARE") {
      title = "TOTAL CARE";
    }
    return (
      <View>
        <View style={styles.header}>
          <Text style={styles.headerText}>{title} RESULTS</Text>
          <Text>
            <Image source={DownArrow} style={{ width: 24, height: 24 }} />
          </Text>
        </View>
        <View
          style={{
            width: "100%",
            height: 2,
            backgroundColor: "#EFEFEF",
          }}
        ></View>
      </View>
    );
  };

  const _updateSections = (activeSections) => {
    setActiveSections(activeSections);
  };

  const SECTIONS = !results
    ? []
    : Object.keys(results).map((key) => {
        return {
          title: key,
          content: results[key],
        };
      });

  const HISTORY_MODE = (
    <Accordion
      sections={SECTIONS}
      activeSections={activeSections}
      renderHeader={_renderHeader}
      renderContent={(section) => (
        <ResultsSummary
          navigation={navigation}
          section={section}
          setMode={setMode}
          setResultID={setResultID}
        />
      )}
      onChange={_updateSections}
    />
  );
  const ADD_NEW_MODE = (
    <>
      <TouchableOpacity onPress={() => setMode("history")}>
        <View style={{ marginTop: 10 }}>
          <Text style={styles.viewAllResults}>← View All Results</Text>
        </View>
      </TouchableOpacity>
      <View
        style={{
          height: 2,
          width: "100%",
          backgroundColor: "#DEDEDE",
          marginTop: 24,
        }}
      ></View>
      <AddNewTestResultForm
        test_type={mode}
        setMode={setMode}
        fetchResults={fetchResults}
      />
    </>
  );

  const DETAILS_MODE = (
    <View>
      <TouchableOpacity
        onPress={() => {
          setMode("history");
        }}
      >
        <View style={{ marginTop: 10 }}>
          <Text style={styles.viewAllResults}>← View All Results</Text>
        </View>
      </TouchableOpacity>
      <ResultDetails
        navigation={navigation}
        result_id={resultID}
        setMode={setMode}
        fetchResults={fetchResults}
      />
    </View>
  );

  const MODES = {
    history: HISTORY_MODE,
    details: DETAILS_MODE,
  };

  let content = ADD_NEW_MODE;
  if (Object.keys(MODES).includes(mode)) {
    content = MODES[mode];
  }
  const save = async (
    csvString: string,
    filename: string,
    mimetype: string
  ) => {
    const directory = FileSystem.documentDirectory + "RPM_Results.csv";
    try {
      await FileSystem.writeAsStringAsync(directory, csvString);

      if (Platform.OS === "android") {
        const permissions =
          await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync();
        if (permissions.granted) {
          const newFileUri =
            await FileSystem.StorageAccessFramework.createFileAsync(
              permissions.directoryUri,
              filename,
              mimetype
            );
          await FileSystem.writeAsStringAsync(newFileUri, csvString);
        } else {
          shareAsync(directory);
        }
      } else {
        shareAsync(directory);
      }
    } catch (error) {
      console.error("Error writing file:", error);
    }
  };

  const exportResultsToCSV = async () => {
    const testResultsConcat: any[] = [];
    let tests = Object.keys(results);
    tests.forEach((test_type: string) => {
      testResultsConcat.push(...results[test_type]);
    });

    const header = [
      "test_type",
      "test_time",
      "systolic",
      "diastolic",
      "bpm",
      "weight",
      "glucose_value",
      "spo2",
      "cardiac_care_weight_lbs",
    ];

    const replacer = (key: string, value: any) => (value === null ? "" : value);
    const csvString = [
      header.join(","),
      ...testResultsConcat.map((result: any) =>
        header
          .map((fieldName: string) =>
            JSON.stringify(result[fieldName], replacer)
          )
          .join(",")
      ),
    ].join("\n");

    if (Platform.OS === "web") {
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "RPM_Results.csv";
      anchor.click();
      URL.revokeObjectURL(url);
    } else {
      save(csvString, "RPM_Results.csv", "text/csv");
    }
  };

  return (
    <View style={styles.container}>
      <Header title="Results" />

      {SECTIONS.length == 0 && fetchedResults ? <EmptyResultsModal /> : null}

      <ScrollView>{content}</ScrollView>

      {SECTIONS.length > 0 && fetchedResults ? (
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            onPress={exportResultsToCSV}
            style={styles.downloadButton}
          >
            <Text style={styles.buttonText}>Download Test Results (CSV)</Text>
          </TouchableOpacity>
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  subheadline: {
    fontSize: 21,
    fontWeight: "bold",
    color: "#818181",
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    marginBottom: 16,
  },
  header: {
    padding: 16,
    paddingLeft: 24,
    paddingRight: 24,
    display: "flex",
    flexDirection: "row",
  },
  headerText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FF6182",
    width: "90%",
  },
  viewAllResults: {
    paddingLeft: 24,
    fontWeight: "bold",
    color: "#FF6182",
  },
  buttonContainer: {
    alignItems: "center",
    marginBottom: 12,
  },
  downloadButton: {
    backgroundColor: "#FE5E85",
    padding: 16,
    borderRadius: 16,
    alignSelf: "center",
  },
  buttonText: { color: "white", fontWeight: "bold", textAlign: "center" },
});
