import React, { useState } from "react";
import {
  Platform,
  View,
  StyleSheet,
  Text,
  FlatList,
  Image,
  TouchableOpacity,
  Dimensions,
  ScrollView,
} from "react-native";

import Glucose from "./assets/glucose.png";
import AthelasOne from "./assets/athelas_one.png";
import Weight from "./assets/weight.png";
import HeartRate from "./assets/heart_rate.png";
import PulseOximeter from "./assets/pulse_ox.png";
import { getResults } from "../Results/actions.ts";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import { trackEvent } from "../../../utils/tracking";

type PropsType = {
  navigation: any;
};

const TEST_TO_IMAGE_MAP = {
  BLOOD_PRESSURE: HeartRate,
  CARDIAC_CARE: Weight,
  WEIGHT: Weight,
  HEART_RATE: HeartRate,
  GLUCOSE: Glucose,
  PULSE_OXIMETRY: PulseOximeter,
};

export function ActivePrograms(props: PropsType) {
  const [data, setData] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);
  const parseData = (testType, data) => {
    if (testType === "GLUCOSE") {
      return `${data["glucose_value"]} mg/dL`;
    } else if (testType === "BLOOD_PRESSURE") {
      return `${data["systolic"]} / ${data["diastolic"]}`;
    } else if (testType === "HEART_RATE") {
      return `${data["bpm"]} BPM`;
    } else if (testType === "WEIGHT") {
      return `${data["weight"]} lbs`;
    } else if (testType === "CARDIAC_CARE") {
      return `${data["cardiac_care_weight_lbs"]} lbs`;
    } else if (testType === "PULSE_OXIMETRY") {
      return `${data["spo2"]} % SpO2`;
    } else {
      return "invalid";
    }
  };

  if (!fetchedData) {
    AsyncStorage.getItem("token").then((token) => {
      if (!token) {
        return;
      }
      getResults(token)
        .then((j) => j.json())
        .then((res) => {
          setData(
            Object.keys(res).map((key) => {
              return {
                test_type: key,
                result: parseData(key, res[key][0]),
                date: moment(res[key].created_at).format("MMM D, YYYY"),
                id: res[key][0].id,
              };
            })
          );
        });
    });
    setFetchedData(true);
  }

  const getTitle = (test_type: string) => {
    let title = test_type?.replace("_", " ");
    if (title === "CARDIAC CARE") {
      title = "TOTAL CARE";
    }
    return title;
  };

  const addNewResult = (test_type) => {
    console.log("test type: " + test_type);
    console.log("navigating to Results");
    // props.navigation.navigate('Results')
    props.navigation.navigate("Results", {
      goTo: "add_new",
      test_type: test_type,
    });
  };

  return (
    <View>
      <Text style={styles.subheadline}>Active Programs</Text>
      <FlatList
        data={data}
        style={{ padding: 24, paddingTop: 0 }}
        numColumns={2}
        scrollEnabled={false}
        renderItem={({ item }) => {
          const data = item;
          return (
            <View style={styles.result_container}>
              <TouchableOpacity
                onPress={() =>
                  props.navigation.navigate("Results", {
                    goTo: "details",
                    result_id: data.id,
                    test_type: data.test_type,
                  })
                }
              >
                <View>
                  <Image
                    source={TEST_TO_IMAGE_MAP[data.test_type]}
                    style={{
                      width: (Dimensions.get("window").width / 8) * 3 - 30,
                      height: (Dimensions.get("window").width / 8) * 3 - 30,
                      backgroundColor: "#DDD",
                      borderRadius: 4,
                    }}
                  />
                  <Text style={styles.test_type_title}>
                    {getTitle(data.test_type)}
                  </Text>
                  <Text style={styles.test_result}>{data.result}</Text>
                  <Text style={styles.test_date}>{data.date}</Text>
                </View>
              </TouchableOpacity>
            </View>
          );
        }}
      />
      <TouchableOpacity
        onPress={() => {
          props.navigation.navigate("Results");
        }}
      >
        <View style={styles.loginButtonContainer}>
          <Text style={styles.loginButtonText}>VIEW ALL TEST RESULTS</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  subheadline: {
    fontSize: 21,
    fontWeight: "bold",
    color: "#818181",
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    marginBottom: 0,
    marginTop: 16,
  },
  plus_container: {
    backgroundColor: "#E4F6EB",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    height: 30,
    width: "100%",
    borderRadius: 8,
    marginTop: 8,
  },
  plus: {
    color: "#1BB55C",
    fontSize: 32,
    fontWeight: "bold",
    marginTop: -6,
    textAlign: "center",
  },
  detail: {
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: 0,
    marginBottom: 0,
  },
  result_container: {
    backgroundColor: "#FFF",
    padding: 16,
    margin: 24,
    marginLeft: 12,
    marginRight: 12,
    borderRadius: 6,
    minWidth: 120,
    width: (Dimensions.get("window").width / 8) * 3,
    shadowColor: "#BFBFBF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 6,
    shadowOpacity: 1,
    elevation:
      Platform.OS === "ios" || Platform.OS === "android" ? 10 : "inherit",
  },
  test_type_title: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#888",
    textAlign: "center",
    marginTop: 16,
  },
  test_result: {
    textAlign: "center",
    fontSize: 20,
    marginTop: 8,
  },
  test_date: {
    backgroundColor: "#DDD",
    textAlign: "center",
    borderRadius: 16,
    padding: 8,
    fontSize: 13,
    marginTop: 16,
    color: "#555",
  },
  loginButtonContainer: {
    backgroundColor: "#DDD",
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    marginLeft: 24,
    marginRight: 24,
    borderRadius: 16,
    textAlign: "center",
    marginBottom: 48,
  },
  loginButtonText: { color: "#888", fontWeight: "bold", textAlign: "center" },
  grid: {
    paddingLeft: 24,
    paddingRight: 24,
    flexWrap: "wrap",
    display: "flex",
    flex: 2,
  },
});
