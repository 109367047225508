import AsyncStorage from "@react-native-async-storage/async-storage";
import { removePatientIdFromDevice } from "../pages/Main/Dashboard/api"

export function logout(navigation: any) {
  AsyncStorage.getItem("patient_id")
    .then(patient_id => removePatientIdFromDevice(patient_id));
  
  AsyncStorage.removeItem("token")
    .then(() => AsyncStorage.removeItem("patient_id"))
    .then(() => navigation?.navigate("Login"));
}
