import AsyncStorage from "@react-native-async-storage/async-storage";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import React, { useState, useEffect, useRef } from "react";
import { getLastBottleTakenOut } from "./api";
import {
  Platform,
  View,
  Dimensions,
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  Touchable,
  TextInput,
  Image,
} from "react-native";
import Checkbox from "expo-checkbox";
import moment from "moment";
import PilltrackAddDetail from "./PilltrackAddDetail";
import FunnelImage from "./assets/Funnel.png";
import StickerImage from "./assets/Sticker.png";
import ReturnImage from "./assets/Return.png";

const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

export default function PilltrackAdd({
  slotNumbers,
  setSelectedMed,
  retrieveData,
}) {
  const [step, setStep] = useState(0);
  const [slotNumber, setSlotNumber] = useState(null);
  const [bottleNumber, setBottleNumber] = useState(null);
  const [medicationName, setMedicationName] = useState("");

  const PilltrackDisplay = () => {
    console.log(slotNumbers);
    const pillbottles_row1 = [0, 1, 2, 3];
    const pillbottles_row2 = [4, 5, 6, 7];
    const pilltrack_row1 = pillbottles_row1.map((pillbottle) => (
      <View
        key={"pb" + pillbottle}
        style={[
          styles.pillbottle,
          {
            backgroundColor: slotNumbers.includes(pillbottle)
              ? "#E85B81"
              : "#E2E2E2",
          },
        ]}
      >
        <Text
          style={[
            styles.pillbottleText,
            { color: slotNumbers.includes(pillbottle) ? "white" : "#E85B81" },
          ]}
        >
          {pillbottle + 1}
        </Text>
      </View>
    ));
    const pilltrack_row2 = pillbottles_row2.map((pillbottle) => (
      <View
        key={"pb" + pillbottle}
        style={[
          styles.pillbottle,
          {
            backgroundColor: slotNumbers.includes(pillbottle)
              ? "#E85B81"
              : "#E2E2E2",
          },
        ]}
      >
        <Text
          style={[
            styles.pillbottleText,
            { color: slotNumbers.includes(pillbottle) ? "white" : "#E85B81" },
          ]}
        >
          {pillbottle + 1}
        </Text>
      </View>
    ));
    return (
      <View style={styles.pilltrack}>
        <View style={[styles.row, { justifyContent: "space-around" }]}>
          {pilltrack_row1}
        </View>
        <View style={{ height: 20 }} />
        <View style={[styles.row, { justifyContent: "space-around" }]}>
          {pilltrack_row2}
        </View>
      </View>
    );
  };

  useEffect(() => {
    AsyncStorage.getItem("token").then((token) => {
      const interval = setInterval(() => {
        getLastBottleTakenOut(token)
          .then((j) => j.json())
          .then((res) => {
            const eventTime = moment(res.timestamp);
            const now = moment().subtract(5, "seconds");
            const lastPickedUp = res.last_bottle_number;
            if (
              lastPickedUp != null &&
              lastPickedUp !== -1 &&
              eventTime.isAfter(now)
            ) {
              setSlotNumber(res.slot_number);
              setBottleNumber(lastPickedUp);
              setStep(1);
            }
          })
          .catch((error) => console.log(error));
      }, 500);
      return () => clearInterval(interval);
    });
  });

  const notSetupSlots = [0, 1, 2, 3, 4, 5, 6, 7].filter(
    (x) => !slotNumbers.includes(x)
  );

  const stepZeroText =
    slotNumbers.length < 8 ? (
      <Text style={styles.instructions}>
        Bottle(s){" "}
        {notSetupSlots
          .map((v) => v + 1)
          .filter(Number)
          .join(", ")}{" "}
        have not been set up. To set up a medication, lift the empty bottle you
        wish to set up from your PillTrack.{" "}
      </Text>
    ) : (
      <Text style={styles.instructions}>All bottles currently set up</Text>
    );

  const StepZero = () => {
    return (
      <View>
        <View style={{ height: 40 }} />
        <PilltrackDisplay />
        <View style={{ height: 40 }} />
        <View style={styles.textContainer}>{stepZeroText}</View>
      </View>
    );
  };

  const StepOne = () => {
    return (
      <PilltrackAddDetail
        slotNumber={slotNumber}
        bottleNumber={bottleNumber}
        passName={setMedicationName}
        setStep={setStep}
      />
    );
  };

  const StepTwo = () => {
    return (
      <View>
        <Image source={FunnelImage} style={styles.image} resizeMode="contain" />
        <View style={styles.textContainer}>
          <Text style={styles.pinkSubtitle}>Add Medication (1/3)</Text>
          <Text style={styles.instructions}>
            Don't forget to fill this bottle with your medication{" "}
            {medicationName}.{" "}
          </Text>
        </View>
        <View>
          <TouchableOpacity onPress={() => setStep(3)}>
            <View style={[styles.primaryButton, { marginTop: 32 }]}>
              <Text style={styles.primaryButtonText}>Next Step</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const StepThree = () => {
    return (
      <View>
        <Image
          source={StickerImage}
          style={styles.image}
          resizeMode="contain"
        />
        <View style={styles.textContainer}>
          <Text style={styles.pinkSubtitle}>Label this Bottle (2/3)</Text>
          <Text style={styles.instructions}>
            Write down the information about {medicationName} on the provided
            stickers, then attach to the bottle.{" "}
          </Text>
        </View>
        <View>
          <TouchableOpacity onPress={() => setStep(4)}>
            <View style={[styles.primaryButton, { marginTop: 32 }]}>
              <Text style={styles.primaryButtonText}>Next Step</Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={{ height: 10 }} />
      </View>
    );
  };

  const StepFour = () => {
    return (
      <View>
        <Image source={ReturnImage} style={styles.image} resizeMode="contain" />
        <View style={styles.textContainer}>
          <Text style={styles.pinkSubtitle}>Return Bottle (3/3)</Text>
          <Text style={styles.instructions}>
            You have successfully set up {medicationName} for this bottle!
            Please put this pill bottle back into its slot.
          </Text>
        </View>
        <View>
          <TouchableOpacity
            onPress={() => {
              wait(500).then(() => retrieveData());
              setSelectedMed(-1);
            }}
          >
            <View style={[styles.primaryButton, { marginTop: 32 }]}>
              <Text style={styles.primaryButtonText}>I'm Done!</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <View>
      <TouchableOpacity
        style={{ backgroundColor: "white" }}
        onPress={() => setSelectedMed(-1)}
      >
        <View style={{ backgroundColor: "white", marginTop: 10 }}>
          <Text style={styles.backButton}>← Back to Edit Pilltrack</Text>
        </View>
      </TouchableOpacity>
      {step == 0 ? <StepZero /> : null}
      {step == 1 ? <StepOne /> : null}
      {step == 2 ? <StepTwo /> : null}
      {step == 3 ? <StepThree /> : null}
      {step == 4 ? <StepFour /> : null}
    </View>
  );
}

const styles = StyleSheet.create({
  backButton: {
    fontWeight: "bold",
    color: "#FF6182",
    marginBottom: 12,
  },
  innerContainer: {
    width: "85%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 16,
    marginBottom: 64,
  },
  subtitle: {
    fontSize: 20,
    fontFamily: "Manrope_400Regular",
    color: "#453D3F",
  },
  card: {
    backgroundColor: "white",
    borderRadius: 12,
    shadowColor: "#666699",
    shadowOpacity: 0.3,
    shadowRadius: 8,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 24,
    elevation: 4,
  },
  pilltrack: {
    backgroundColor: "white",
    borderRadius: 16,
    shadowColor: "grey",
    shadowOpacity: 0.5,
    shadowRadius: 8,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 16,
  },
  pillbottle: {
    backgroundColor: "#E85B81",
    borderRadius: 1000,
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    fontFamily: "Manrope_700Bold",
    color: "#453D3F",
    fontWeight: "bold",
  },
  subtext: {
    fontSize: 14,
    color: "#453D3F",
    fontFamily: "Manrope_700Bold",
  },
  image: {
    width: "70%",
    aspectRatio: 1,
    marginLeft: "auto",
    marginRight: "auto",
  },
  arrow: {
    position: "absolute",
    right: 0,
  },
  label: {
    borderRadius: 100,
    padding: 8,
    paddingLeft: 12,
    paddingRight: 12,
    alignItems: "center",
    marginRight: 6,
  },
  textInput: {
    backgroundColor: "#F5F5F5",
    paddingLeft: 12,
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 8,
    fontSize: 24,
    fontFamily: "Manrope_700Bold",
    color: "#808080",
    marginTop: 24,
  },
  checkboxContainer: {
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  checkbox: {
    padding: 8,
    borderColor: "#E85B81",
    height: 32,
    width: 32,
    color: "#E85B81",
  },
  primaryButton: {
    backgroundColor: "#E85B81",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 8,
  },
  primaryButtonText: {
    color: "white",
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Manrope_400Regular",
  },
  secondaryButton: {
    backgroundColor: "white",
    borderColor: "#E85B81",
    borderWidth: 1,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 8,
  },
  secondaryButtonText: {
    color: "#E85B81",
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Manrope_400Regular",
  },
  pillbottleText: {
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Manrope_700Bold",
  },
  textContainer: {
    marginTop: 10,
    textAlign: "center",
    width: "85%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  verticalAlign: {
    justifyContent: "center",
    flex: 1,
    flexDirection: "column",
  },
  pinkSubtitle: {
    fontSize: 24,
    fontFamily: "Manrope_700Bold",
    color: "#E85B81",
    textAlign: "center",
  },
  instructions: {
    fontSize: 24,
    fontFamily: "Manrope_400Regular",
    color: "#808080",
  },
});
