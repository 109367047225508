import React from "react";
import { Dimensions, View, Text, Linking, TouchableOpacity, StyleSheet, ScrollView} from "react-native";
// import { ScrollView } from "react-native-gesture-handler";

type PropsType = {};

export function PatientConsent(props: PropsType) {
  return (
    <ScrollView style={{ height: Dimensions.get("window").height }}>
      <View style={{ backgroundColor: "white", padding: 16 }}>
        <Text style = {styles.h1}>
          Athelas, Inc.
        </Text>
        <Text style = {styles.h1}>
            Patient Consent for {"\n"}
            Remote Monitoring Program
        </Text>
        <Text>
            This consent form (“Consent”) reviews the benefits, risks and
            limitations of you, the patient, utilizing the remote monitoring
            (“Remote Monitoring”) services (the “RPM Services”) provided by
            Athelas, Inc. (referred to as “Athelas”, “we”, “us” or “our”) at the
            direction of your healthcare provider. It also explains how your
            information will be used after performance of the Remote Monitoring
            Services. You will not receive Remote Monitoring Services unless you
            confirm that you have read and understood the contents of this form.
        </Text>
        <Text>
            By clicking on the box, you indicate that this Consent is a binding
            agreement and that you have read and understood the following terms.
            Capitalized terms used but not defined in this Consent have the
            meaning given to them in our other policies, including our Terms of
            Use (athelas.com/terms-of-use) and Privacy Policy (athelas.com/privacy-policy
            ), which are incorporated by reference into this Consent.
        </Text>
        <Text>
            By clicking your acceptance, you have chosen to use our Remote
            Monitoring Services.{" "}
        </Text>
          <Text style = {styles.h1}>1. Consent to Remote Monitoring Services</Text>
        <Text >
          <Text>
            Our Remote Monitoring Services are offered and available to users
            who are residents of the United States. Remote Monitoring covers a
            suite of services that may be ordered by your doctor individually or
            in combination, including Remote Physiologic Monitoring (“RPM”),
            Remote Patient Monitoring, Chronic Care Management (“CCM”),
            Principal Care Management (“PCM”), Collaborative Care Management
            (CoCM), or another care management regime deemed appropriate by your
            healthcare provider, each of which is referred to generically herein
            as Remote Monitoring. By agreeing to receive Remote Monitoring, you
            agree to receive one or more of these services at the discretion of
            your doctor. Your use of Athelas's Remote Monitoring Services is
            voluntary. It is your choice whether to utilize our Remote
            Monitoring Services or not. Prior to signing this Consent, you may
            wish to speak with your healthcare provider for further guidance
            about our Remote Monitoring Services.
          </Text>
        </Text>
          <Text style = {styles.h1}>2. Procedures and Protocol</Text>
        <Text>
          <Text>
            When you sign up for Remote Monitoring Services, we will collect
            some information from you. We cannot perform the Remote Monitoring
            Services without collecting information from you, but we will only
            collect information that will assist us in providing the Remote
            Monitoring Services that you have requested. In order for the Remote
            Monitoring Services to be provided as intended, you must provide
            accurate and correct information. Remote Monitoring Services will
            not be provided to you unless authorized by your Healthcare Provider{" "}
          </Text>
        </Text>
        <Text>
          <Text>
            The results of our Remote Monitoring Services will be made available
            to you either through our site or via text message. You authorize
            this communication by Athelas. You acknowledge that you may
            terminate this authorization at any time by emailing{" "}
          </Text>
          <TouchableOpacity onPress={() => Linking.openURL('mailto:privacy@athelas.com') }>
            <Text style = {styles.link}>mailto:privacy@athelas.com</Text>
          </TouchableOpacity>
          <Text>
            , but understand that doing so may Result in a termination of Remote
            Monitoring Services by Athelas.{" "}
          </Text>
        </Text>
        <Text style = {styles.h1}>3. Clinical Review</Text>
        <Text>
          <Text>Athelas clinical staff (“</Text>
          <Text>Clinicians</Text>
          <Text>
            ”), under the general supervision of your healthcare provider, may
            undertake clinical review of your Remote Monitoring program. As part
            of that review, Clinicians will reach out to you no less than
            monthly to discuss, via phone or video chat, your ongoing Remote
            Monitoring Services, to provide recommendations, or to suggest you
            seek the advice of your healthcare provider. You agree that
            Clinicians may contact you via the methods set forth above, that you
            will respond to any voicemail left by a Clinician promptly in order
            to discuss your Remote Monitoring program, and that you will update
            your contact information with Athelas if it changes after the
            initiation of the Remote Monitoring program. You acknowledge that
            you may terminate this authorization at any time by emailing{" "}
          </Text>
          <TouchableOpacity onPress={() => Linking.openURL('mailto:privacy@athelas.com') }>
            <Text style = {styles.link}>mailto:privacy@athelas.com</Text>
          </TouchableOpacity>
          <Text>
            , but understand that doing so may result in a termination of Remote
            Monitoring Services by Athelas.
          </Text>
        </Text>
        <Text style = {styles.h1}>4. Remote Monitoring Devices</Text>
        <Text>
          <Text>
            Any Remote Monitoring devices (the “Devices”) you receive from
            Athelas are provided solely for the purpose of providing Remote
            Monitoring Services and remain Athelas's Property. You agree to not
            give the devices to another person without Athelas's written
            consent. If you cease using the Devices, you agree that you will, at
            Athelas's direction, either dispose of, donate, or return the
            devices to Athelas.{" "}
          </Text>
        </Text>
        <Text style = {styles.h1}>5. Agreement to Use of Devices</Text>
        <Text>
          <Text>
            To continue receiving Remote Monitoring Services, you agree to
            utilize each device provided to you by Athelas no less than once per
            month at the frequency instructed by your healthcare provider.
            Failure to do so may result in a termination of Remote Monitoring
            services and an obligation to return the devices to Athelas.
          </Text>
        </Text>
        <Text style = {styles.h1}>6. Access to Athelas Portal</Text>
        <Text>
          <Text>
            As part of Remote Monitoring Services, Athelas may provide you with
            access to a patient-facing online portal where you can review Remote
            Monitoring results. If you do so, you agree to follow the Athelas
            Privacy Policy (
          </Text>
          <Text>athelas.com/privacy-policy</Text>
          <Text>) and Terms of Use (</Text>
          <Text>athelas.com/terms-of-use</Text>
          <Text>
            ), which are incorporated by reference into this Consent.{" "}
          </Text>
        </Text>
        <Text style = {styles.h1}>7. Patient Responsibility</Text>
        <Text>
          <Text>
            Athelas and your provider work to minimize the out-of-pocket costs
            you'll be required to pay in order to receive Remote Monitoring
            Services. Despite the optimization, however, you may still be
            responsible for the payment of a copay, coinsurance, or other costs
            resulting from application of your insurance plan's deductible
            (collectively, “Patient Responsibility”). By consenting to receive
            Remote Monitoring Services, you acknowledge, accept, and agree to
            pay promptly all Patient Responsibility resulting from the Remote
            Monitoring Services.
          </Text>
        </Text>
        <Text style = {styles.h1}>8. Risks and Benefits</Text>
        <Text>
          <Text>
            Utilizing our Remote Monitoring Services can help you learn more
            about your health. You may use this information to make more
            informed health care decisions and choices. Disclosing certain
            information may make you uncomfortable. Our Remote Monitoring
            Services may reveal sensitive information about your health. This
            information may be distressing.
          </Text>
        </Text>
        <Text>
          <Text>
            Health information that received by your healthcare providers may
            become part of your medical record and through that route may be
            accessible to other healthcare providers and/or insurance companies
            in the future. Information that you share with family, friends or
            employers may also be used against your interests. Even if you share
            health information that has no or limited meaning today, that
            information could have greater meaning in the future as new
            discoveries are made.
          </Text>
        </Text>
        <Text>
          <Text>
            Athelas, or the Healthcare Provider who reviews your results, may
            advise you to have a follow-up visit with your doctor after
            reviewing the results of your Remote Monitoring Services. As a
            result, you may learn about health conditions and problems or
            potential health risks that you were not aware of before you
            utilized our Remote Monitoring Services. You may experience stress,
            anxiety, or emotional or physical discomfort when you learn about
            health problems or potential health problems. There may also be
            additional risks of utilizing our Remote Monitoring Services that
            are currently unforeseeable.
          </Text>
        </Text>
        <Text style = {styles.h1}>9. Limitations on Remote Monitoring Services</Text>
        <Text>
          <Text>
            OUR SERVICES DO NOT PROVIDE MEDICAL ADVICE AND SHOULD ONLY BE USED
            BY A LICENSED HEALTHCARE PROVIDER TO RENDER CARE. The information
            and content provided are being gathered on behalf of your healthcare
            provider. Always seek the advice of your physician or other
            qualified health care provider with any questions you have regarding
            your medical care, and never disregard professional medical advice
            or delay seeking it because of information received as part of
            Remote Monitoring Services. Nothing contained in the Remote
            Monitoring Services is intended to constitute a medical diagnosis or
            treatment. By clicking your acceptance, you understand and agree
            that your results are not a substitute for professional medical
            advice.{" "}
          </Text>
        </Text>
        <Text style = {styles.h1}>10. Record Retention</Text>
        <Text>
          <Text>
            We are subject to multiple laws on the retention of data.
            Accordingly, we retain any information collected about you for as
            long as we are required to maintain it for regulatory and compliance
            purposes or for a legal or business necessity.
          </Text>
        </Text>
        <Text>
          <Text>
            You understand that by utilizing Remote Monitoring Services, you
            acquire no rights in any research or commercial products that may be
            developed by Athelas or its collaborating partners. You specifically
            understand that you will not receive compensation for any research
            or commercial products that include or result from your sample or
            information.
          </Text>
        </Text>
        <Text>
          <Text>
            Your information and test results may be stored in a repository and
            used for validation, educational, and/or research purposes. By
            clicking your acceptance of this Consent, you acknowledge and agree
            that Athelas may de-identify the information that is obtained from
            our Remote Monitoring Services and that we may aggregate this
            information with de-identified information from other users.
            De-identification means that the personally identifiable information
            associated with your information will be removed. You understand
            that we will not disclose, sell and otherwise commercialize
            personally identifiable information but that we may disclose, sell
            and otherwise commercialize de-identified information and user
            content without restriction.
          </Text>
        </Text>
        <Text>
          <Text>
            We may incorporate different or additional technologies to test or
            analyze data in the future. We may, at our sole discretion, choose
            to analyze your data using such technologies. We are not obligated
            to notify you if we chose to conduct different or additional testing
            or analysis on your data. Your receipt of Remote Monitoring Services
            does not automatically include any such different or additional
            technologies.{" "}
          </Text>
        </Text>
        <Text style = {styles.h1}>11. Confidentiality</Text>
        <Text>
          <Text>
            By clicking your acceptance of this Consent, you acknowledge that
            you have read and understand our Privacy Policy (
          </Text>
          <Text>athelas.com/privacy-policy</Text>
          <Text>
            ). You agree the Athelas is not liable for the unauthorized release
            of your results or information unless such unauthorized release was
            the result of gross negligence or willful misconduct on the part of
            Athelas.
          </Text>
        </Text>
        <Text style = {styles.h1}>12. Withdrawal of Consent</Text>
        <Text >
          <Text >
            Your use of Athelas's Remote Monitoring Services is voluntary. You
            may choose to withdraw your Consent or to stop using Athelas's
            Remote Monitoring Services at any time. Such requests should be sent
            to us by email at{" "}
          </Text>
          <TouchableOpacity  onPress={() => Linking.openURL('mailto:privacy@athelas.com') }>
            <Text style = {styles.link}>mailto:privacy@athelas.com</Text>
          </TouchableOpacity>
          <Text>. </Text>
        </Text>
        <Text>
          <Text>
            Please note that while any such requests you make will be reflected
            in our databases within a reasonable period of time, we may retain
            your information in the ordinary course of business, for the
            satisfaction of our legal obligations, or where we otherwise
            reasonably believe that we have a legitimate reason to do so.
            Information that has already been de-identified, anonymized, or
            aggregated may not be retrievable or traced back for destruction,
            deletion, or amendment.
          </Text>
        </Text>
        <Text style = {styles.h1}>13. Legal Agreement</Text>
        <Text>
          <Text>
            You give permission for Athelas, its representatives, affiliates,
            staff, agents, and designees to perform the requested Remote
            Monitoring Services and to disclose your information and results to
            your healthcare provider and in accordance with our Privacy Policy.{" "}
          </Text>
        </Text>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  h1: {
    fontSize: 16,
    fontWeight: "800",
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10
  },
  link: {
    color: "#0645AD",
    textDecorationLine: 'underline'
  }
});