import React, { useState } from "react";
import {
  Platform,
  View,
  StyleSheet,
  Text,
  FlatList,
  Image,
  TouchableOpacity,
  Dimensions,
  ScrollView,
} from "react-native";

import Glucose from "./assets/glucose.png";
import AthelasOne from "./assets/athelas_one.png";
import Weight from "./assets/weight.png";
import HeartRate from "./assets/heart_rate.png";
import { getResults, getRecentResults } from "../Results/actions.ts";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import { shouldShowPilltrackTab } from "../Medications/api";

type PropsType = {
  navigation: any;
};

const TEST_TO_IMAGE_MAP = {
  BLOOD_PRESSURE: HeartRate,
  CARDIAC_CARE: Weight,
  WEIGHT: Weight,
  HEART_RATE: HeartRate,
  GLUCOSE: Glucose,
};

export function RecentPilltrack(props: PropsType) {
  const [showPilltrackTab, setShowPilltrackTab] = useState(false);
  AsyncStorage.getItem("token").then((token) => {
    shouldShowPilltrackTab(token)
      .then((j) => j.json())
      .then((res) => {
        setShowPilltrackTab(res["show_pilltrack_tab"]);
      });
  });

  if (!showPilltrackTab) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Text style={styles.subheadline}>PillTrack</Text>
      <TouchableOpacity
        onPress={() => {
          props.navigation.navigate("PillTrack");
        }}
      >
        <View style={{ height: 20 }}></View>
        <View style={styles.loginButtonContainer}>
          <Text style={styles.loginButtonText}>VIEW PILLTRACK ADHERENCE</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  subheadline: {
    fontSize: 21,
    fontWeight: "bold",
    color: "#818181",
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    marginBottom: 0,
    marginTop: 16,
  },
  detail: {
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: 0,
    marginBottom: 0,
  },
  entry: {
    backgroundColor: "#FFF",
    padding: 16,
    margin: 8,
    marginRight: 16,
    borderRadius: 4,
    minWidth: 150,
    width: (Dimensions.get("window").width / 8) * 3,
    shadowColor: "#BFBFBF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 6,
    shadowOpacity: 1,
    elevation:
      Platform.OS === "ios" || Platform.OS === "android" ? 10 : "inherit",
  },
  test_type_title: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#888",
    textAlign: "center",
    marginTop: 16,
  },
  test_result: {
    textAlign: "center",
    fontSize: 20,
    marginTop: 8,
  },
  test_date: {
    backgroundColor: "#DDD",
    textAlign: "center",
    borderRadius: 16,
    padding: 8,
    fontSize: 13,
    marginTop: 16,
    color: "#555",
  },
  loginButtonContainer: {
    backgroundColor: "#DDD",
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    marginLeft: 24,
    marginRight: 24,
    borderRadius: 16,
    textAlign: "center",
  },
  loginButtonText: { color: "#888", fontWeight: "bold", textAlign: "center" },
  grid: {
    paddingLeft: 24,
    paddingRight: 24,
    flexWrap: "wrap",
    display: "flex",
    flex: 2,
  },
});
