import AsyncStorage from "@react-native-async-storage/async-storage";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import React, { useState, useEffect, useRef } from "react";
import { changePillTrackBottle } from "./api";
import {
  Platform,
  View,
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  Touchable,
  TextInput,
} from "react-native";
import Checkbox from "expo-checkbox";

const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

export default function PilltrackDetail({
  schedule,
  medicationNames,
  selectedMed,
  setSelectedMed,
  bottleNumbers,
  retrieveData,
}) {
  const [isMorningChecked, setMorningChecked] = useState(
    ["BI_DAILY", "DAILY_AM", "TID", "AM_NOON"].includes(schedule[selectedMed])
  );
  const [isNoonChecked, setNoonChecked] = useState(
    ["TID", "AFTERNOON", "AM_NOON", "NOON_EVENING"].includes(
      schedule[selectedMed]
    )
  );
  const [isEveningChecked, setEveningChecked] = useState(
    ["BI_DAILY", "DAILY_PM", "TID", "NOON_EVENING"].includes(
      schedule[selectedMed]
    )
  );
  const [medicationName, setMedicationName] = useState(
    medicationNames[selectedMed]
  );

  const [nameCollision, setNameCollision] = useState(false);

  useEffect(() => {
    if (
      medicationNames.includes(medicationName) &&
      medicationName != medicationNames[selectedMed]
    ) {
      setNameCollision(true);
    } else {
      setNameCollision(false);
    }
  }, [medicationName]);

  function updateMedications(
    bottleNumber: number,
    medicationName?: string,
    schedule?: string,
    disabled?: boolean
  ) {
    AsyncStorage.getItem("token").then((token) => {
      console.log(schedule);
      changePillTrackBottle(
        token,
        bottleNumber,
        medicationName,
        schedule,
        disabled
      ).then((res) => {
        if (res.status !== 200) {
          console.log("Update Bottle Failed!");
        } else {
          console.log("Successfully Updated Bottle");
        }
      });
    });
  }

  function getSchedule() {
    if (isMorningChecked) {
      if (isNoonChecked) {
        if (isEveningChecked) {
          return "TID"; //Morning, Noon, Evening
        } else {
          return "AM_NOON"; //Morning, Noon
        }
      } else {
        if (isEveningChecked) {
          return "BI_DAILY"; //Morning, Evening
        } else {
          return "DAILY_AM"; //Morning
        }
      }
    } else {
      if (isNoonChecked) {
        if (isEveningChecked) {
          return "NOON_EVENING"; // Noon, Evening
        } else {
          return "AFTERNOON"; // Noon
        }
      } else {
        if (isEveningChecked) {
          return "DAILY_PM"; //Evening
        } else {
          return "DAILY_AM"; //Default to Morning
        }
      }
    }
  }

  return (
    <View>
      <TouchableOpacity
        style={{ backgroundColor: "white" }}
        onPress={() => setSelectedMed(-1)}
      >
        <View style={{ backgroundColor: "white", marginTop: 10 }}>
          <Text style={styles.backButton}>← Back to Edit Pilltrack</Text>
        </View>
      </TouchableOpacity>
      <View style={{ height: 20 }} />
      <View>
        <Text style={[styles.title, { color: "#E85B81", textAlign: "center" }]}>
          Medication Name
        </Text>
        <Text
          style={[
            styles.subtitle,
            { color: "#808080", textAlign: "center", marginTop: 12 },
          ]}
        >
          Medication Name of this bottle
        </Text>
        <TextInput
          style={styles.textInput}
          value={medicationName}
          onChangeText={setMedicationName}
        />
      </View>
      <View>
        {nameCollision && (
          <Text style={styles.errortext}>
            You've already set up a medication with this name. Please use
            another name.
          </Text>
        )}
      </View>

      <View style={{ height: 40 }} />
      <View>
        <Text style={[styles.title, { color: "#E85B81", textAlign: "center" }]}>
          Medication Schedule
        </Text>
        <Text
          style={[
            styles.subtitle,
            { color: "#808080", textAlign: "center", marginTop: 12 },
          ]}
        >
          Please select all that apply
        </Text>
      </View>
      <View style={styles.checkboxContainer}>
        <View
          style={[
            styles.row,
            { justifyContent: "space-between", marginTop: 12 },
          ]}
        >
          <Text style={[styles.subtitle, { color: "#808080" }]}>
            Every Morning
          </Text>
          <Checkbox
            style={styles.checkbox}
            value={isMorningChecked}
            onValueChange={setMorningChecked}
            color={isMorningChecked ? "#E85B81" : undefined}
          />
        </View>
        <View
          style={[
            styles.row,
            { justifyContent: "space-between", marginTop: 24 },
          ]}
        >
          <Text style={[styles.subtitle, { color: "#808080" }]}>
            Every Noon
          </Text>
          <Checkbox
            style={styles.checkbox}
            value={isNoonChecked}
            onValueChange={setNoonChecked}
            color={isNoonChecked ? "#E85B81" : undefined}
          />
        </View>
        <View
          style={[
            styles.row,
            { justifyContent: "space-between", marginTop: 24 },
          ]}
        >
          <Text style={[styles.subtitle, { color: "#808080" }]}>
            Every Evening
          </Text>
          <Checkbox
            style={styles.checkbox}
            value={isEveningChecked}
            onValueChange={setEveningChecked}
            color={isEveningChecked ? "#E85B81" : undefined}
          />
        </View>
      </View>
      <View>
        <TouchableOpacity
          onPress={() => {
            updateMedications(
              bottleNumbers[selectedMed],
              medicationName,
              getSchedule()
            );
            wait(500).then(() => retrieveData());
            setSelectedMed(-1);
          }}
          disabled={nameCollision}
        >
          <View
            style={[
              styles.primaryButton,
              { marginTop: 32 },
              nameCollision && { backgroundColor: "#CECECE" },
            ]}
          >
            <Text style={styles.primaryButtonText}>Update Medication</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            updateMedications(
              bottleNumbers[selectedMed],
              "",
              getSchedule(),
              true
            );
            wait(250).then(() => retrieveData());
            setSelectedMed(-1);
          }}
        >
          <View style={[styles.secondaryButton, { marginTop: 24 }]}>
            <Text style={styles.secondaryButtonText}>Delete Medication</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  backButton: {
    fontWeight: "bold",
    color: "#FF6182",
    marginBottom: 12,
  },
  innerContainer: {
    width: "85%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 16,
    marginBottom: 64,
  },
  subtitle: {
    fontSize: 20,
    fontFamily: "Manrope_400Regular",
    color: "#453D3F",
  },
  card: {
    backgroundColor: "white",
    borderRadius: 12,
    shadowColor: "#666699",
    shadowOpacity: 0.3,
    shadowRadius: 8,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 24,
    elevation: 4,
  },
  pilltrack: {
    backgroundColor: "white",
    borderRadius: 16,
    shadowColor: "grey",
    shadowOpacity: 0.5,
    shadowRadius: 8,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 16,
  },
  pillbottle: {
    backgroundColor: "#E85B81",
    borderRadius: 1000,
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: 'space-around'
  },
  title: {
    fontSize: 20,
    fontFamily: "Manrope_700Bold",
    color: "#453D3F",
    fontWeight: "bold",
  },
  subtext: {
    fontSize: 14,
    color: "#453D3F",
    fontFamily: "Manrope_700Bold",
  },
  arrow: {
    position: "absolute",
    right: 0,
  },
  label: {
    borderRadius: 100,
    padding: 8,
    paddingLeft: 12,
    paddingRight: 12,
    alignItems: "center",
    marginRight: 6,
  },
  textInput: {
    backgroundColor: "#F5F5F5",
    paddingLeft: 12,
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 8,
    fontSize: 24,
    fontFamily: "Manrope_700Bold",
    color: "#808080",
    marginTop: 24,
  },
  checkboxContainer: {
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  checkbox: {
    padding: 8,
    borderColor: "#E85B81",
    height: 32,
    width: 32,
    color: "#E85B81",
  },
  primaryButton: {
    backgroundColor: "#E85B81",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 8,
  },
  primaryButtonText: {
    color: "white",
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Manrope_400Regular",
  },
  secondaryButton: {
    backgroundColor: "white",
    borderColor: "#E85B81",
    borderWidth: 1,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 8,
  },
  secondaryButtonText: {
    color: "#E85B81",
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Manrope_400Regular",
  },
  pillbottleText: {
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Manrope_700Bold",
  },
  textContainer: {
    marginTop: 50,
    textAlign: "center",
    width: "85%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  verticalAlign: {
    justifyContent: "center",
    flex: 1,
    flexDirection: "column",
  },
  instructions: {
    fontSize: 24,
    fontFamily: "Manrope_400Regular",
    color: "#808080",
  },
  errortext: {
    fontFamily: "Manrope_400Regular",
    fontSize: 16,
    textAlign: "center",
    color: "#ff0000",
    paddingTop: 10,
    paddingLeft: 32,
    paddingRight: 32,
  },
});
