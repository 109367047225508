import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TextInput,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";
import { logout } from "../../../utils/auth";
import { Header } from "../Header";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getProfile } from "../Dashboard/actions";
import { trackEvent } from "../../../utils/tracking";
import {
  Payer,
  queryPayers,
  InsuranceProfile,
  updateInsuranceInfo,
  Profile,
} from "./actions";
import Autocomplete from "react-native-autocomplete-input";
import throttle from "lodash/throttle";

// type PropsType = {
//   navigation: any;
// };

export function Settings({ navigation }) {
  const [profile, setProfile] = useState<Profile>({
    first_name: "",
    last_name: "",
    phone: "",
    address: {},
    points: "",
    onboardingToken: "",
    payer_name: "",
    payer_id: "",
    policy_number: "",
  });
  const [currentInsuranceNameQuery, setCurrentInsuranceNameQuery] =
    useState("");
  const [payerSearchResult, setPayerSearchResult] = useState<Payer[]>([]);
  const [selectedInsurance, setSelectedInsurance] = useState<Payer | null>(
    null
  );
  const [closeDropdown, setCloseDropdown] = useState(true);
  const [newPolicyNumber, setNewPolicyNumber] = useState("");
  const [insuranceFocus, setInsuranceFocus] = useState(false);
  const [policyFocus, setPolicyFocus] = useState(false);
  let [activeInsurancePolicyFound, setActiveInsurancePolicyFound] =
    useState(true);
  let [backendValidationError, setBackEndValidationError] = useState("");
  let [isLoadingResponse, setIsLoadingResponse] = useState(false);
  let [policyNumberEmpty, setPolicyNumberEmpty] = useState(false);
  let [insuranceEmpty, setInsuranceEmpty] = useState(false);
  let [queryError, setQueryError] = useState(false);
  const autocompleteTextInputRef = useRef<TextInput | null>(null);

  const fetchProfile = () => {
    AsyncStorage.getItem("token").then((token) => {
      getProfile(token)
        .then((j) => {
          if (j.status === 200) {
            return j.json();
          } else {
            logout(navigation);
          }
        })
        .then((res) => {
          setProfile(res);
          // Check if patient has insurance info
          if (res.payer_name !== "" && res.payer_id !== "") {
            setCurrentInsuranceNameQuery(
              `${res.payer_name} [Payer ID: ${res.payer_id}]`
            );
            setSelectedInsurance({
              payer_name: res.payer_name,
              payer_id: res.payer_id,
            });
          }
          setNewPolicyNumber(`${res.policy_number}`);
        });
    });
  };

  useEffect(() => {
    fetchProfile();
    trackEvent("view", "viewed_settings");
  }, []);

  const searchPayers = (query: string) => {
    const trimmedQuery = query.trim();
    if (trimmedQuery === "") {
      setPayerSearchResult([]);
    } else {
      queryPayers(trimmedQuery)
        .then((response) => {
          if (selectedInsurance)
            response.data.payers.unshift(selectedInsurance);

          const dedupedPayers = Array.from(
            new Map(
              response.data.payers.map((v) => [
                `${v.payer_name}-${v.payer_id}`,
                v,
              ])
            ).values()
          );

          setPayerSearchResult(dedupedPayers);
        })
        .catch((err) => {
          console.log("ERROR QUERYING PAYERS", err);
        });
    }
  };

  const searchPayersThrottled = useMemo(() => throttle(searchPayers, 500), []);

  const formattedPhoneNumber = profile.phone.replace(
    /(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/,
    function (_, p1, p2, p3, p4) {
      let output = "";
      if (p1) output = `(${p1}`;
      if (p2) output += `${p2})`;
      if (p3) output += ` - ${p3}`;
      if (p4) output += ` - ${p4}`;
      return output;
    }
  );

  const closeAutocompleteDropdown = () => {
    if (autocompleteTextInputRef.current) {
      autocompleteTextInputRef.current.blur();
    }
    setCloseDropdown(true);
    setInsuranceFocus(false);
  };

  const openAutocompleteDropdown = () => {
    setCloseDropdown(false);
    setInsuranceFocus(true);
  };

  const resetAutocompleteQuery = () => {
    // Reset the query text displayed to the user to the last selected insurance
    setCurrentInsuranceNameQuery(
      !selectedInsurance
        ? ""
        : `${selectedInsurance.payer_name} [Payer ID: ${selectedInsurance.payer_id}]`
    );
    closeAutocompleteDropdown();
  };

  const saveChanges = () => {
    // Reset to last selected insurance if patient clicks save mid-query
    if (!closeDropdown) {
      resetAutocompleteQuery();
    }

    // Check that insurance info is not empty before updating the patient's profile
    if (!selectedInsurance || newPolicyNumber === "") {
      if (!selectedInsurance) {
        setInsuranceEmpty(true);
      }
      if (newPolicyNumber === "") {
        setPolicyNumberEmpty(true);
      }
      return;
    }

    AsyncStorage.getItem("token").then((token) => {
      const newInsuranceProfile: InsuranceProfile = {
        payer_id: selectedInsurance.payer_id,
        payer_name: selectedInsurance.payer_name,
        policy_number: newPolicyNumber,
      };
      setIsLoadingResponse(true);
      updateInsuranceInfo(token, newInsuranceProfile)
        .then((resp) => {
          setQueryError(false);
          setIsLoadingResponse(false);
          setActiveInsurancePolicyFound(
            resp.data.active_insurance_policy_found
          );
          setBackEndValidationError(
            resp.data.patient_information_validation_error
          );
          // Update local profile if no errors
          if (
            resp.data.active_insurance_policy_found &&
            resp.data.patient_information_validation_error === ""
          ) {
            setProfile({
              ...profile,
              payer_id: selectedInsurance.payer_id,
              payer_name: selectedInsurance.payer_name,
              policy_number: newPolicyNumber,
            });
          }
        })
        .catch((err) => {
          setQueryError(true);
          setIsLoadingResponse(false);
        });
    });
  };

  const revertChanges = () => {
    setInsuranceEmpty(false);
    setPolicyNumberEmpty(false);
    closeAutocompleteDropdown();

    setActiveInsurancePolicyFound(true);
    setBackEndValidationError("");

    if (profile.payer_name !== "" && profile.payer_id !== "") {
      setCurrentInsuranceNameQuery(
        `${profile.payer_name} [Payer ID: ${profile.payer_id}]`
      );
      setSelectedInsurance({
        payer_name: profile.payer_name,
        payer_id: profile.payer_id,
      });
    } else {
      setCurrentInsuranceNameQuery("");
      setSelectedInsurance(null);
    }
    setNewPolicyNumber(`${profile.policy_number}`);
  };

  const helperTextInsuranceCompany = () => {
    if (backendValidationError !== "") {
      return backendValidationError;
    } else if (insuranceEmpty) {
      return "Please select an insurance company";
    } else {
      return null;
    }
  };

  const helperTextPolicyNumber = () => {
    if (!activeInsurancePolicyFound) {
      return "No active plan found under this policy number";
    } else if (policyNumberEmpty) {
      return "Please enter your policy insurance number";
    } else {
      return null;
    }
  };

  return (
    <View style={styles.container}>
      <Header title="Settings"></Header>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={{ flexGrow: 1 }}
        nestedScrollEnabled={true}
      >
        <TouchableWithoutFeedback
          onPress={() => {
            // Close the Autocomplete dropdown if user clicks outside of dropdown while its open
            // Doing it this way because using Autocomplete's onBlur prop prevents dropdown items from being selected
            if (!closeDropdown) {
              resetAutocompleteQuery();
            }
          }}
        >
          <View style={{ flex: 1 }}>
            <View>
              <Text style={styles.subheadline}>⚙ Personal Information</Text>

              <Text style={styles.labelDisabled}>FIRST NAME</Text>
              <Text style={styles.textInputDisabled}>{profile.first_name}</Text>

              <Text style={styles.labelDisabled}>LAST NAME</Text>
              <Text style={styles.textInputDisabled}>{profile.last_name}</Text>

              <Text style={styles.labelDisabled}>PHONE NUMBER</Text>
              <Text style={styles.textInputDisabled}>
                {formattedPhoneNumber}
              </Text>

              <Text
                style={
                  insuranceFocus
                    ? styles.labelFocus
                    : insuranceEmpty
                    ? styles.labelError
                    : styles.labelBlur
                }
              >
                INSURANCE COMPANY
              </Text>
              {(insuranceEmpty || backendValidationError !== "") && (
                <Text style={styles.helperText}>
                  {helperTextInsuranceCompany()}
                </Text>
              )}
              <View style={{ flex: 1, zIndex: 1 }}>
                {/* Prevent touches on the Autocomplete from being propagated up (component does not work on web without it) */}
                <TouchableWithoutFeedback onPress={(e) => e.stopPropagation()}>
                  <View style={styles.autocompleteContainer}>
                    <Autocomplete
                      ref={autocompleteTextInputRef}
                      autoCapitalize="none"
                      autoComplete="off"
                      autoCorrect={false}
                      style={[
                        insuranceFocus
                          ? styles.textInputFocus
                          : insuranceEmpty
                          ? styles.textInputError
                          : styles.textInputBlur,
                        { marginLeft: 0, marginRight: 0 },
                        Platform.OS === "web" && { outline: "none" },
                      ]}
                      inputContainerStyle={{ borderWidth: 0 }}
                      data={payerSearchResult}
                      value={currentInsuranceNameQuery}
                      onChangeText={(text) => {
                        setCurrentInsuranceNameQuery(text);
                        searchPayersThrottled(text);
                      }}
                      renderResultList={({ data }) => (
                        <ScrollView style={styles.queryListContainer}>
                          {data.map((item, index) => (
                            <View key={index}>
                              <TouchableOpacity
                                onPress={() => {
                                  setInsuranceEmpty(false);
                                  setSelectedInsurance(item);
                                  setCurrentInsuranceNameQuery(
                                    `${item.payer_name} [Payer ID: ${item.payer_id}]`
                                  );
                                  closeAutocompleteDropdown();
                                  Keyboard.dismiss();
                                }}
                                style={{
                                  paddingHorizontal: 10,
                                  paddingVertical: 15,
                                }}
                              >
                                <Text>
                                  {item.payer_name} [Payer ID: {item.payer_id}]
                                </Text>
                              </TouchableOpacity>
                              {index != payerSearchResult.length - 1 && (
                                <View
                                  style={{
                                    height: 1,
                                    width: "100%",
                                    backgroundColor: "#EFEFEF",
                                  }}
                                />
                              )}
                            </View>
                          ))}
                        </ScrollView>
                      )}
                      hideResults={closeDropdown}
                      onFocus={() => {
                        openAutocompleteDropdown();
                      }}
                      onEndEditing={() => {
                        if (
                          currentInsuranceNameQuery === "" ||
                          payerSearchResult.length === 0
                        ) {
                          resetAutocompleteQuery();
                        }
                      }}
                    />
                  </View>
                </TouchableWithoutFeedback>
              </View>

              <Text
                style={[
                  policyFocus
                    ? styles.labelFocus
                    : policyNumberEmpty
                    ? styles.labelError
                    : styles.labelBlur,
                  { marginTop: 50 },
                ]}
              >
                POLICY NUMBER
              </Text>
              {(policyNumberEmpty || !activeInsurancePolicyFound) && (
                <Text style={styles.helperText}>
                  {helperTextPolicyNumber()}
                </Text>
              )}
              <TextInput
                autoCapitalize="none"
                autoCompleteType="off"
                autoCorrect={false}
                style={[
                  policyFocus
                    ? styles.textInputFocus
                    : policyNumberEmpty
                    ? styles.textInputError
                    : styles.textInputBlur,
                  ,
                  Platform.OS === "web" && { outline: "none" },
                ]}
                value={newPolicyNumber}
                onChangeText={(text) => {
                  setPolicyNumberEmpty(false);
                  setNewPolicyNumber(text);
                }}
                onFocus={() => {
                  setPolicyFocus(true);
                  resetAutocompleteQuery(); // For when user attempts to switch focus away from Autocomplete mid-query
                }}
                onBlur={() => setPolicyFocus(false)}
              />
              <View style={{ marginTop: 24 }}>
                {queryError && (
                  <Text style={[styles.helperText, { textAlign: "center" }]}>
                    An unexpected error occurred when trying to save your info.
                    Please try again.
                  </Text>
                )}
                <TouchableOpacity
                  disabled={isLoadingResponse}
                  style={styles.saveButtonContainer}
                  onPress={() => saveChanges()}
                >
                  <View>
                    {isLoadingResponse ? (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <ActivityIndicator style={{ marginHorizontal: 10 }} />
                        <Text style={styles.buttonText}>
                          VERIFYING INFORMATION
                        </Text>
                      </View>
                    ) : (
                      <Text style={styles.buttonText}>SAVE CHANGES</Text>
                    )}
                  </View>
                </TouchableOpacity>

                <TouchableOpacity
                  disabled={isLoadingResponse}
                  style={styles.cancelButtonContainer}
                  onPress={() => revertChanges()}
                >
                  <View>
                    <Text style={styles.buttonText}>CANCEL</Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  width: "100%",
                  marginTop: 24,
                  marginBottom: 24,
                  height: 1,
                  backgroundColor: "#EFEFEF",
                }}
              ></View>

              <TouchableOpacity
                onPress={() => logout(navigation)}
                style={styles.logoutButtonContainer}
              >
                <View>
                  <Text style={styles.buttonText}>LOGOUT</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  subheadline: {
    fontSize: 21,
    fontWeight: "bold",
    color: "#818181",
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    marginBottom: 16,
  },
  labelFocus: {
    color: "#FE5E85",
    fontSize: 13,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 4,
    fontWeight: "bold",
    marginTop: 16,
  },
  labelBlur: {
    color: "#000",
    fontSize: 13,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 4,
    fontWeight: "bold",
    marginTop: 16,
  },
  labelError: {
    color: "red",
    fontSize: 13,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 4,
    fontWeight: "bold",
    marginTop: 16,
  },
  labelDisabled: {
    color: "#888",
    fontSize: 13,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 4,
    fontWeight: "bold",
    marginTop: 16,
  },
  textInputFocus: {
    borderWidth: 2,
    borderColor: "#FE5E85",
    borderStyle: "solid",
    borderRadius: 4,
    padding: 8,
    marginLeft: 24,
    marginRight: 24,
  },
  textInputBlur: {
    borderWidth: 2,
    borderColor: "#000",
    borderStyle: "solid",
    borderRadius: 4,
    padding: 8,
    marginLeft: 24,
    marginRight: 24,
  },
  textInputDisabled: {
    borderWidth: 2,
    borderColor: "#888",
    borderStyle: "solid",
    borderRadius: 4,
    padding: 8,
    marginLeft: 24,
    marginRight: 24,
    color: "#888",
  },
  textInputError: {
    borderWidth: 2,
    borderColor: "red",
    borderStyle: "solid",
    borderRadius: 4,
    padding: 8,
    marginLeft: 24,
    marginRight: 24,
  },
  loginButtonContainer: {
    backgroundColor: "#FE5E85",
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 16,
    marginTop: 24,
    marginRight: 24,
    marginLeft: 24,
  },
  logoutButtonContainer: {
    backgroundColor: "#eb4034",
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 16,
    marginTop: 24,
    marginRight: 24,
    marginLeft: 24,
  },
  buttonText: { color: "white", fontWeight: "bold", textAlign: "center" },
  saveButtonContainer: {
    backgroundColor: "#FE5E85",
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 16,
    marginRight: 24,
    marginLeft: 24,
  },
  cancelButtonContainer: {
    backgroundColor: "#888",
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 16,
    marginTop: 24,
    marginRight: 24,
    marginLeft: 24,
  },
  autocompleteContainer: {
    flex: 1,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    marginLeft: 24,
    marginRight: 24,
  },
  queryListContainer: {
    maxHeight: 300,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderColor: "#FE5E85",
    borderWidth: 2,
  },
  helperText: {
    color: "red",
    fontSize: 12,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 4,
    fontWeight: "bold",
    marginTop: 4,
  },
});
