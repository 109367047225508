import React, { useState } from "react";
import {
  Platform,
  View,
  StyleSheet,
  Text,
  FlatList,
  Image,
  TouchableOpacity,
  Dimensions,
  ScrollView,
} from "react-native";

import Glucose from "./assets/glucose.png";
import AthelasOne from "./assets/athelas_one.png";
import Weight from "./assets/weight.png";
import HeartRate from "./assets/heart_rate.png";
import PulseOximeter from "./assets/pulse_ox.png";
import { getResults, getRecentResults } from "../Results/actions.ts";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";

type PropsType = {
  navigation: any;
};

const TEST_TO_IMAGE_MAP = {
  BLOOD_PRESSURE: HeartRate,
  CARDIAC_CARE: Weight,
  WEIGHT: Weight,
  HEART_RATE: HeartRate,
  GLUCOSE: Glucose,
  PULSE_OXIMETER: PulseOximeter,
};

export function RecentResults(props: PropsType) {
  const [data, setData] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);

  const parseData = (testType, data) => {
    if (testType === "GLUCOSE") {
      return `${data["glucose_value"]} mg/dL`;
    } else if (testType === "BLOOD_PRESSURE") {
      return `${data["systolic"]} / ${data["diastolic"]}`;
    } else if (testType === "HEART_RATE") {
      return `${data["bpm"]} BPM`;
    } else if (testType === "WEIGHT") {
      return `${data["weight"]} lbs`;
    } else if (testType === "CARDIAC_CARE") {
      return `${data["cardiac_care_weight_lbs"]} lbs`;
    } else if (testType === "PULSE_OXIMETRY") {
      return `${data["spo2"]} % SpO2`;
    } else {
      return "invalid";
    }
  };

  if (!fetchedData) {
    AsyncStorage.getItem("token").then((token) => {
      if (!token) {
        return;
      }
      getRecentResults(token, 20)
        .then((j) => j.json())
        .then((res) => {
          setData(res);
        });
    });
    setFetchedData(true);
  }

  const getTitle = (test_type: string) => {
    let title = test_type?.replace("_", " ");
    if (title === "CARDIAC CARE") {
      title = "TOTAL CARE";
    }
    return title;
  };

  return (
    <View style={styles.container}>
      <Text style={styles.subheadline}>Recent Test Results</Text>

      <ScrollView style={{ padding: 24, paddingTop: 8 }} horizontal>
        {data.map((entry) => {
          const { test_type, id } = entry;
          return (
            <TouchableOpacity
              style={styles["entry"]}
              onPress={() => {
                props.navigation.navigate("Results", {
                  goTo: "details",
                  result_id: id,
                  test_type: test_type,
                });
              }}
              key={id}
            >
              <View>
                <Text style={{ fontSize: 13, fontWeight: "bold" }}>
                  {test_type.replace(/_/g, "")}
                </Text>
              </View>
              <Text style={{ fontSize: 20, marginTop: 4 }}>
                {parseData(test_type, entry)}
              </Text>
              <Text
                style={{
                  fontSize: 13,
                  marginTop: 8,
                  textTransform: "uppercase",
                  color: "#888",
                }}
              >
                {moment(entry["created_at"]).format("MMM D, YYYY")}
              </Text>
            </TouchableOpacity>
          );
        })}
        <View style={{ width: Dimensions.get("window").width * 0.6 }}></View>
      </ScrollView>

      <TouchableOpacity
        onPress={() => {
          props.navigation.navigate("Results");
        }}
      >
        <View style={styles.loginButtonContainer}>
          <Text style={styles.loginButtonText}>VIEW ALL TEST RESULTS</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  subheadline: {
    fontSize: 21,
    fontWeight: "bold",
    color: "#818181",
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    marginBottom: 0,
    marginTop: 16,
  },
  detail: {
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: 0,
    marginBottom: 0,
  },
  entry: {
    backgroundColor: "#FFF",
    padding: 16,
    margin: 8,
    marginRight: 16,
    borderRadius: 4,
    minWidth: 150,
    width: (Dimensions.get("window").width / 8) * 3,
    shadowColor: "#BFBFBF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 6,
    shadowOpacity: 1,
    elevation:
      Platform.OS === "ios" || Platform.OS === "android" ? 10 : "inherit",
  },
  test_type_title: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#888",
    textAlign: "center",
    marginTop: 16,
  },
  test_result: {
    textAlign: "center",
    fontSize: 20,
    marginTop: 8,
  },
  test_date: {
    backgroundColor: "#DDD",
    textAlign: "center",
    borderRadius: 16,
    padding: 8,
    fontSize: 13,
    marginTop: 16,
    color: "#555",
  },
  loginButtonContainer: {
    backgroundColor: "#DDD",
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    marginLeft: 24,
    marginRight: 24,
    borderRadius: 16,
    textAlign: "center",
  },
  loginButtonText: { color: "#888", fontWeight: "bold", textAlign: "center" },
  grid: {
    paddingLeft: 24,
    paddingRight: 24,
    flexWrap: "wrap",
    display: "flex",
    flex: 2,
  },
});
