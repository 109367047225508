import AsyncStorage from "@react-native-async-storage/async-storage";
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import React, { useState, useEffect, useRef } from "react";
import {
  getMedicationAdherencePilltrack,
  getWeeklyResults,
  shouldShowPilltrackTab,
} from "./api";
import { RefreshControl, Platform, View, ScrollView, Text, StyleSheet, TouchableOpacity } from "react-native";
import { logout } from "../../../utils/auth";
import { Header } from "../Header";
import moment, {Moment} from "moment";
import { trackEvent } from "../../../utils/tracking";
import { PillTrackOnboarding } from "../Dashboard/PillTrackOnboarding";
import { Ionicons } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import { Entypo } from '@expo/vector-icons'; 
import {Calendar, CalendarList, Agenda} from 'react-native-calendars';

import {
    useFonts,
    Manrope_400Regular,
    Manrope_500Medium,
    Manrope_700Bold,
} from "@expo-google-fonts/manrope";


type CalendarDate = {
  index: number,
  dateString: string,
  selectedDate: {calendarCurrentDate: {selected: Boolean}}
};

export function PilltrackAdherence({setMode}) {
    let [fontsLoaded] = useFonts({
      Manrope_400Regular,
      Manrope_500Medium,
      Manrope_700Bold,
    });
  
    const [hasOnboarded, setHasOnboarded] = useState(true);
    const [data, setData] = useState([]);
    const [totalTaken, setTotalTaken] = useState(0);
    const [totalDoses, setTotalDoses] = useState(0);
  
    useEffect(() => {
      AsyncStorage.getItem("token").then((token) => {
        getMedicationAdherencePilltrack(token, 29)
          .then((j) => j.json())
          .then((res) => {
            setTotalDoses(res["total_doses"]);
            setData(res["med_results"]);
            setTotalTaken(res["total_taken_count"]);
          });
        shouldShowPilltrackTab(token)
          .then((j) => j.json())
          .then((res) => {
            setHasOnboarded(res["has_onboarded_pilltrack"]);
          });
      });
      trackEvent("view", "viewed_pilltrack_tab");
    }, []);

    const startDate = moment().subtract(1, "month").format("MMMM Do");
    const currentDate = moment().format("MMMM Do");
    const calendarStartDate = moment().subtract(1, "month").format("YYYY-MM-DD");
    const calendarCurrentDate = moment().format("YYYY-MM-DD")
    const rangeHeader = startDate + " - " + currentDate;
    const [selectedDate, setSelectedDate] = useState<CalendarDate>(
      {
        index: 0,
        dateString: moment().format("dddd, MMMM DD"),
        selectedDate: {calendarCurrentDate: {selected: true}},
      }
    );
    
    const dateColors = {}
    const markedDates = {}
    data.map((events,index) => {
      events.sort(function (a, b) {
        const dateA = a["time"];
        const dateB = b["time"];
        // Compare the 2 dates
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      });
      let showRed = false;
      let noMedicationsEnrolled = false;
  
      if (events.length === 0) {
        noMedicationsEnrolled = true;
      } else {
        if (events.some((e) => e["time"] == 0)) {
          showRed = true;
        }
      }
  
      dateColors[moment().subtract(index, "days").format("YYYY-MM-DD")] = showRed;
  
      markedDates[moment().subtract(index, "days").format("YYYY-MM-DD")] = {
        selected: noMedicationsEnrolled ? false : true,
        selectedColor: showRed ? "#E85B81" : "#7DB8EF",
      };
    });
  
    const getSelectedDayEvents = (date) => {
      let selectedDate = {};
      let index = moment(calendarCurrentDate).diff(moment(date), 'days')
      let serviceDate = moment(date).format("dddd, MMMM DD");
      if (dateColors[date] != null) {
        selectedDate[date] = {selected: true, selectedColor: (dateColors[date] ? '#f2a6ba': '#bbdaf7')}
      } else {
        selectedDate[date] = {selected: true, selectedColor: '#d9d9d9'}
      }
      setSelectedDate({
        index: index,
        dateString: serviceDate,
        selectedDate: selectedDate
      })
    }
  
    let adherence = 0;
    if (totalDoses > 0) {
      adherence = Math.round((totalTaken / totalDoses) * 100);
    }
  
    const createCalendar = () => {
      return (
        <View style = {styles.calendarContainer}>
        <Calendar
          current={calendarCurrentDate}
          minDate={calendarStartDate}
          maxDate={calendarCurrentDate}
          onDayPress={day => {
            getSelectedDayEvents(day.dateString);
            trackEvent("click", "opened_daily_pilltrack");
          }}
          onDayLongPress={day => {
          }}
          onMonthChange={month => {
          }}
          monthFormat= {'MMMM yyyy'}
          hideArrows={false}
          hideExtraDays={true}
          disableMonthChange={false}
          renderArrow = {(direction) => {
            if (direction == "left")
              return (
                  <AntDesign name="left" size={24} color="black" />
              );
            if (direction == "right")
              return (
                  <AntDesign name="right" size={24} color="black" />
              );
          }}
          firstDay={0}
          hideDayNames={false}
          showWeekNumbers={false}
          onPressArrowLeft={subtractMonth => subtractMonth()}
          onPressArrowRight={addMonth => addMonth()}
          disableArrowLeft={false}
          disableArrowRight={false}
          disableAllTouchEventsForDisabledDays={true}
          enableSwipeMonths={true}
          horizontal={true}
          markedDates={{...markedDates, ...selectedDate.selectedDate}}      
          theme={{
            backgroundColor: '#ffffff',
            calendarBackground: '#ffffff',
            textSectionTitleColor: 'black',
            textSectionTitleDisabledColor: 'black',
            // selectedDayBackgroundColor: '#7DB8EF',
            selectedDayTextColor: 'white',
            todayTextColor: 'black',
            dayTextColor: 'black',
            textDisabledColor: '#E1E1E1',
            dotColor: '#00adf5',
            selectedDotColor: '#ffffff',
            arrowColor: 'black',
            disabledArrowColor: '#d9e1e8',
            monthTextColor: 'black',
            indicatorColor: 'blue',
            textDayFontFamily: 'Manrope_500Medium',
            textMonthFontFamily: 'Manrope_700Bold',
            textDayHeaderFontFamily: 'Manrope_700Bold',
            textDayFontWeight: '300',
            textMonthFontWeight: 'bold',
            textDayHeaderFontWeight: 'bold',
            textDayFontSize: 15,
            textMonthFontSize: 18,
            textDayHeaderFontSize: 14,
            
          }}
        />
        </View>
      )
    }
  
    const createDailyMeds = () => {
      const events = data[selectedDate.index]
      if (events) {
      events.sort(function (a, b) {
        const dateA = a["time"];
        const dateB = b["time"];
        // Compare the 2 dates
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      });
      }
        return (
          events ?
          <View>
              {events.map((med) => {
              const timeTaken =
              med["time"] !== 0 ? moment(med["time"]).format("h:mm A") : "";
                  return (
                    <View style={styles.rowEvents} key = {med['schedule'].toString() + med['medication'].toString()}>
                      <Text style={styles.medText}>
                        {"  " + med["medication"] + "  "}
                      </Text>
                      <Text style={styles.timeText}>{timeTaken}</Text>
                      {med["time"] === 0 ? 
                        <Text style={styles.timeText}>
                          {med["schedule"]}
                        </Text>
                      : null }
                      {med["time"] === 0 ?
                        <View style = {styles.missedIcon}><Text style = {styles.missedText}>Missed</Text></View>:
                        <View style = {styles.takenIcon}><Text style = {styles.takenText}>Taken</Text></View>
                    }
                    </View>
                  );
                })}
                </View>  : <View><Text style = {styles.dateRange}></Text></View>
        )
  }
  
    if (!hasOnboarded) {
      return (
        <View>
          <Header title="Pilltrack" />
          <View style={{ marginTop: 24 }}>
          <PillTrackOnboarding />
          </View>
        </View>
      );
    }
  
    return (
      <View>
        <View style = {styles.topCard}>
          <TouchableOpacity style = {{backgroundColor: 'white'}}onPress={() => setMode("main")}>
            <View style={{ backgroundColor: 'white', marginTop: 10 }}>
              <Text style={styles.backButton}>← Back to Pilltrack</Text>
            </View>
          </TouchableOpacity>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text style={styles.title}>My Monthly Adherence</Text>
              <Text style ={styles.subtitle}>{rangeHeader}</Text>
            </View>
            <View>
              <AnimatedCircularProgress 
              size={80} 
              width={8} 
              fill = {Math.min(adherence, 100)}
              backgroundColor = "#E2E2E2"
              tintColor = "#7DB8EF"
              rotation = {0}
              lineCap = "round"
              >{
                (fill) => (
                  <Text style = {styles.circleText}>
                    {Math.min(adherence, 100) + '%'}
                  </Text>
                )
              }</AnimatedCircularProgress>
            </View>
          </View>
          <View contentContainerStyle={styles.scroll}>
            {data ? createCalendar(): null}
          </View>
          <View style = {styles.labelContainer}>
            <View style = {{flexDirection: "row"}}><Text style = {[styles.labelDot, {color: '#7DB8EF'}]}>•</Text><Text style = {styles.label}>All Med(s) Taken</Text></View>
            <View style = {{flexDirection: "row"}}><Text style = {[styles.labelDot, {color: '#E85B81'}]}>•</Text><Text style = {styles.label}>Missed Med(s)</Text></View>
          </View>
        </View>
        <View style = {styles.bottomCard}>
          <Text style = {styles.subtitle}>{selectedDate.dateString}</Text>
          <View>
            {data ? createDailyMeds(): null}
          </View>
        </View>
     </View>
    );
  }
  
  const styles = StyleSheet.create({
    container: {
      width: "100%",
      height: "100%",
      backgroundColor: "white",
    },
    scroll: {
      flexGrow: 1,
      alignItems: "center",
      textAlign: "right",
      marginTop: 24,
    },
    column: {
      flexDirection: "column",
    },
    rowMargin: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 23,
    },
    row: {
      flexDirection: "row",
      marginBottom: 10,
      width: '85%',
      marginLeft: "auto",
      marginRight: "auto",
      justifyContent: 'space-between',
      
    },
    rowEvents: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginTop: 16,
      borderRadius: 8,
      shadowRadius: 15,
      shadowColor: '#1b2142',
      shadowOpacity: 0.1,
      paddingTop: 24,
      paddingBottom: 24,
      paddingLeft: 12,
      paddingRight: 12,
      backgroundColor: 'white',
      elevation: 4
    },
    listTitle: {
      fontFamily: "Manrope_500Medium",
      color: "#453D3F",
      fontSize: 18,
    },
    timeText: {
      fontFamily: "Manrope_400Regular",
      color: "#B2C1C1",
      fontSize: 18,
    },
    missedText: {
      fontFamily: "Manrope_700Bold",
      color: "#FC5378",
      fontSize: 14,
    },
    takenText: {
      fontFamily: "Manrope_700Bold",
      color: "#5E97ED",
      fontSize: 14,
    }
    ,
    medText: {
      fontFamily: "Manrope_400Regular",
      fontSize: 18,
    },
    title: {
      fontSize: 20,
      fontFamily: "Manrope_400Regular",
      color: "#453D3F",
      fontWeight: "bold",
    },
    subtitle: {
      fontSize: 18,
      fontFamily: "Manrope_400Regular",
      color: "#453D3F",
    },
    dateRange: {
      fontFamily: "Manrope_400Regular",
      color: "#453D3F",
      fontSize: 16,
    },
    onboardCallout: {
      fontSize: 20,
      fontFamily: "Manrope_700Bold",
      color: "#6ABFC0",
      textAlign: "center",
      marginLeft: 20,
      marginRight: 20,
    },
    collapseView: {
      borderWidth: 0,
      padding: 0,
    },
    collapseHeader: {
      backgroundColor: "#ffffff",
      borderRadius: 8,
      padding: 16,
      marginLeft: 24,
      marginRight: 24,
      shadowColor: "#BFBFBF",
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowRadius: 10,
      shadowOpacity: 1,
      elevation: (Platform.OS === 'ios' || Platform.OS === 'android')  ? 20 : "inherit",
      marginBottom: 24,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    circleText: {
      color: "#7DB8EF",
      fontSize: 24,
      fontFamily: "Manrope_700Bold",
      fontWeight: "bold",
    },
    calendarContainer: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 0
    },
    topCard: {
      width: '100%',
      shadowColor: '#000',
      shadowOffset: {width: 1, height: 3},
      shadowOpacity: 0.1,
      shadowRadius: 20,
      elevation: 4,
      backgroundColor: 'white'
    },
    labelContainer: {
      flexDirection: 'row',
      justifyContent: 'space-around'
    },
    label: {
      fontFamily: "Manrope_400Regular",
      fontSize: 12,
      color: '#808080',
      marginBottom: 16,
      marginTop: 16
    },
    labelDot: {
      fontFamily: "Manrope_400Regular",
      fontSize: 32,
      color: '#808080',
    },
    bottomCard: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 24,
      marginBottom: 120
    },
    missedIcon: {
      backgroundColor: '#FFE3E3',
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 4
    },
    takenIcon: {
      backgroundColor: '#EFF5FF',
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 4
    },
    backButton: {
      marginLeft: '7.5%',
      fontWeight: "bold",
      color: "#FF6182",
      marginBottom: 12,
      marginTop: 12,
    },
  });