import AsyncStorage from "@react-native-async-storage/async-storage";
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import React, { useState, useEffect, useRef } from "react";
import {
  getMedicationAdherence,
  getWeeklyResults,
  shouldShowMedicationsTab,
} from "./api";
import { Platform, View, ScrollView, Text, StyleSheet } from "react-native";
import { logout } from "../../../utils/auth";
import { Header } from "../Header";
import moment from "moment";
import { trackEvent } from "../../../utils/tracking";
import { MedicationOnboarding } from "../Dashboard/MedicationOnboarding";
import CollapsibleView from "./CollapsibleView";
import { Ionicons } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";

import {
  useFonts,
  Manrope_400Regular,
  Manrope_500Medium,
  Manrope_700Bold,
} from "@expo-google-fonts/manrope";

type PropsType = {};

export function Medications(props: PropsType) {
  let [fontsLoaded] = useFonts({
    Manrope_400Regular,
    Manrope_500Medium,
    Manrope_700Bold,
  });

  const [hasOnboarded, setHasOnboarded] = useState(true);
  const [data, setData] = useState([]);
  const [totalTaken, setTotalTaken] = useState(0);
  const [totalDoses, setTotalDoses] = useState(0);

  useEffect(() => {
    AsyncStorage.getItem("token").then((token) => {
      getMedicationAdherence(token, 7)
        .then((j) => j.json())
        .then((res) => {
          setTotalDoses(res["total_doses"]);
        });
      getWeeklyResults(token, 7)
        .then((j) => j.json())
        .then((res) => {
          setData(res["med_results"]);
          setTotalTaken(res["total_taken_count"]);
        });
      shouldShowMedicationsTab(token)
        .then((j) => j.json())
        .then((res) => {
          setHasOnboarded(res["has_onboarded_gem"]);
        });
    });
    trackEvent("view", "viewed_medications_tab");
  }, []);

  const startDate = moment().subtract(7, "days").format("MMMM Do");
  const currentDate = moment().format("MMMM Do");
  const rangeHeader = startDate + " - " + currentDate;

  let adherence = 0;
  if (totalDoses > 0) {
    adherence = Math.round((totalTaken / totalDoses) * 100);
  }

  const createRows = () => {
    const rows = data.map((events, index) => {
      events.sort(function (a, b) {
        const dateA = a["time"];
        const dateB = b["time"];
        // Compare the 2 dates
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      });
      let showRed = false;
      if (events.some((e) => e["time"] == 0)) {
        showRed = true;
      }
      return ( 
        events.length > 0 ? (
          <View key = {moment().subtract(index, "days").format("dddd, MMMM Do").toString()}>
            <CollapsibleView 
            style = {styles.collapseView}
            handleClick = {() => {
              trackEvent("click", "opened_daily_medication");
            }}
            title =
            {
            <View style={styles.collapseHeader}>
              <View style={{ marginRight: 10 }}>
                {showRed ? 
                  <AntDesign name="minuscircle" size={20} color="#FF6779" />
                : 
                  <Ionicons
                    name="md-checkmark-circle"
                    size={24}
                    color="#6ABFC0"
                  />
                }
              </View>
              <Text style={styles.listTitle}>
                {moment().subtract(index, "days").format("dddd, MMMM Do")}
              </Text>
              <View style={{ marginLeft: 10 }}>
                <AntDesign name="down" size={20} color="#48ABA9" />
              </View>
            </View>}
            >
              <View>
                {events.map((med) => {
                const timeTaken =
                  med["time"] !== 0 ? moment(med["time"]).format("h:mm A") : "";
                return (
                  <View style={styles.rowEvents} key = {med['schedule'].toString() + med['medication'].toString()}>
                    <Text style={styles.timeText}>{timeTaken}</Text>
                    {med["time"] === 0 ? 
                      <Text style={styles.missedText}>
                        {"--" + med["schedule"]}
                      </Text>
                    : null }
                    <Text style={styles.medText}>
                      {"  " + med["medication"] + "  "}
                    </Text>
                  </View>
                );
              })}
              </View>
            </CollapsibleView>
            </View> 
        ): null
      );
    });
    return rows;
  };

  if (!hasOnboarded) {
    return (
      <View>
        <Header title="Medications" />
        <View style={{ marginTop: 24 }}>
          <MedicationOnboarding />
        </View>
        <Text style={styles.onboardCallout}>
          Medication adherence and results will show here once you've onboarded
          above!
        </Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Header title="Medications" />
      <View style={{ marginTop: 24 }}>
        <MedicationOnboarding />
      </View>
      <View style={styles.row}>
      <View style={{ width: 80, height: 80}}>
          <AnimatedCircularProgress 
          size={80} 
          width={10} 
          fill = {Math.min(adherence, 100)}
          backgroundColor = "#E2E2E2"
          tintColor = "#6ABFC0"
          rotation = {0}
          >{
            (fill) => (
              <Text style = {styles.circleText}>
                {Math.min(adherence, 100) + '%'}
              </Text>
            )
          }</AnimatedCircularProgress>
        </View>
        <View style={styles.column}>
          <Text style={styles.title}>Weekly Adherence</Text>
          <Text>{rangeHeader}</Text>
        </View>
      </View>
      <ScrollView contentContainerStyle={styles.scroll}>
          {data ? createRows(): null}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
  },
  scroll: {
    flexGrow: 1,
    alignItems: "center",
    textAlign: "right",
    marginTop: 24,
  },
  column: {
    flexDirection: "column",
    marginLeft: 24,
    textAlign: "center",
  },
  rowMargin: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 23,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  rowEvents: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    marginLeft: 24,
    marginRight: 24,
  },
  listTitle: {
    fontFamily: "Manrope_500Medium",
    color: "#453D3F",
    fontSize: 18,
  },
  timeText: {
    fontFamily: "Manrope_400Regular",
    color: "#B2C1C1",
    fontSize: 18,
  },
  missedText: {
    fontFamily: "Manrope_400Regular",
    color: "#FC5378",
    fontSize: 18,
  },
  medText: {
    fontFamily: "Manrope_400Regular",
    color: "#485454",
    fontSize: 18,
  },
  title: {
    fontSize: 20,
    fontFamily: "Manrope_700Bold",
    color: "#453D3F",
  },
  dateRange: {
    fontFamily: "Manrope_400Regular",
    color: "#453D3F",
    fontSize: 16,
  },
  onboardCallout: {
    fontSize: 20,
    fontFamily: "Manrope_700Bold",
    color: "#6ABFC0",
    height: 22,
    textAlign: "center",
    marginLeft: 20,
    marginRight: 20,
  },
  collapseHeader: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    padding: 16,
    marginLeft: 24,
    marginRight: 24,
    shadowColor: "#BFBFBF",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
    elevation: (Platform.OS === 'ios' || Platform.OS === 'android') ? 20 : "inherit",
    marginBottom: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  pill: {
    borderWidth: 1,
    borderColor: "rgba(0,0,0,0.2)",
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    height: 100,
    backgroundColor: "#fff",
    borderRadius: 50,
  },
});
