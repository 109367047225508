import AsyncStorage from "@react-native-async-storage/async-storage";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import React, { useState, useEffect, useRef } from "react";
import { getPilltrackBottles } from "./api";
import {
  Platform,
  View,
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
  Touchable,
  TextInput,
} from "react-native";
import { logout } from "../../../utils/auth";
import { Header } from "../Header";
import moment, { Moment } from "moment";
import { trackEvent } from "../../../utils/tracking";
import { PillTrackOnboarding } from "../Dashboard/PillTrackOnboarding";
import {
  Ionicons,
  AntDesign,
  Entypo,
  MaterialCommunityIcons,
  MaterialIcons,
  FontAwesome5,
} from "@expo/vector-icons";
import { Calendar, CalendarList, Agenda } from "react-native-calendars";
import Checkbox from "expo-checkbox";
import PilltrackDetail from "./PilltrackDetail";
import PilltrackAdd from "./PilltrackAdd";

import {
  useFonts,
  Manrope_400Regular,
  Manrope_500Medium,
  Manrope_700Bold,
} from "@expo-google-fonts/manrope";

const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

export function PilltrackEdit({ setMode, fetchProfile }) {
  const [retrievedData, setRetrievedData] = useState(false);
  const [medicationNames, setMedicationNames] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [bottleNumbers, setBottleNumbers] = useState([]);
  const [slotNumbers, setSlotNumbers] = useState([]);
  const [selectedMed, setSelectedMed] = useState(-1); // -1 is Main, 0 to 7 is pill bottles, 8 add new bottle
  const [reminderTimes, setReminderTimes] = useState([]);

  interface LabelProps {
    index: number;
    schedule: string;
  }

  const retrieveData = () => {
    AsyncStorage.getItem("token").then((token) => {
      getPilltrackBottles(token)
        .then((j) => j.json())
        .then((res) => {
          setReminderTimes(res["reminder_times"]);
          setMedicationNames(res["medication_names"]);
          setSchedule(res["schedule"]);
          setSlotNumbers(res["slot_numbers"]);
          setBottleNumbers(res["bottle_numbers"]);
        });
    });
  };

  if (!retrievedData) {
    retrieveData();
    setRetrievedData(true);
  }

  const TimeLabel = ({ index, schedule }: LabelProps) => {
    if (reminderTimes[index]) {
      const reminder_time = reminderTimes[index][schedule];
      const standard_reminder_time = moment(reminder_time, "HH:mm:ss").format(
        "h:mm A"
      );
      return (
        <View style={[styles.label, { backgroundColor: "#FDF5F5" }]}>
          <Text
            style={[styles.subtext, { color: "#E85B81" }]}
          >{`${standard_reminder_time}`}</Text>
        </View>
      );
    }
    return <View></View>;
  };

  const MedicationList = () => {
    return (
      <View>
        <TouchableOpacity
          style={{ backgroundColor: "white" }}
          onPress={() => setMode("main")}
        >
          <View style={{ backgroundColor: "white", marginTop: 10 }}>
            <Text style={styles.backButton}>← Back to Pilltrack</Text>
          </View>
        </TouchableOpacity>
        <Text style={styles.title}>
          Which bottle would you like to make changes to?
        </Text>
        {medicationNames.map((medication_name, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => {
              setSelectedMed(index);
            }}
          >
            <View style={styles.card}>
              <View style={[styles.row]}>
                <Text style={styles.subtitle}>{medication_name}</Text>
                <View style={[styles.row, styles.arrow]}>
                  <View style={[styles.row]}>
                    {schedule[index] == "BI_DAILY" ? (
                      <>
                        <TimeLabel index={index} schedule={"DAILY_AM"} />
                        <TimeLabel index={index} schedule={"DAILY_PM"} />
                      </>
                    ) : null}
                    {schedule[index] == "DAILY_PM" ? (
                      <TimeLabel index={index} schedule={"DAILY_PM"} />
                    ) : null}
                    {schedule[index] == "DAILY_AM" ? (
                      <TimeLabel index={index} schedule={"DAILY_AM"} />
                    ) : null}
                    {schedule[index] == "AFTERNOON" ? (
                      <TimeLabel index={index} schedule={"AFTERNOON"} />
                    ) : null}
                    {schedule[index] == "TID" ? (
                      <>
                        <TimeLabel index={index} schedule={"DAILY_AM"} />
                        <TimeLabel index={index} schedule={"AFTERNOON"} />
                        <TimeLabel index={index} schedule={"DAILY_PM"} />
                      </>
                    ) : null}
                    {schedule[index] == "AM_NOON" ? (
                      <>
                        <TimeLabel index={index} schedule={"DAILY_AM"} />
                        <TimeLabel index={index} schedule={"AFTERNOON"} />
                      </>
                    ) : null}
                    {schedule[index] == "NOON_EVENING" ? (
                      <>
                        <TimeLabel index={index} schedule={"AFTERNOON"} />
                        <TimeLabel index={index} schedule={"DAILY_PM"} />
                      </>
                    ) : null}
                  </View>
                  <AntDesign name="right" size={18} color="#453D3F" />
                </View>
              </View>
            </View>
          </TouchableOpacity>
        ))}
        <TouchableOpacity onPress={() => setSelectedMed(8)}>
          <View style={[styles.card, { paddingTop: 16, paddingBottom: 16 }]}>
            <View style={[styles.row]}>
              <Ionicons name="add-circle" size={36} color="#e6e6e6" />
              <Text style={[styles.subtitle, { marginLeft: 12 }]}>
                Set Up A New Bottle
              </Text>
              <View style={[styles.row, styles.arrow]}>
                <AntDesign name="right" size={18} color="#453D3F" />
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={styles.innerContainer}>
      {selectedMed == -1 ? <MedicationList /> : null}
      {selectedMed >= 0 && selectedMed <= 7 ? (
        <PilltrackDetail
          schedule={schedule}
          selectedMed={selectedMed}
          medicationNames={medicationNames}
          setSelectedMed={setSelectedMed}
          bottleNumbers={bottleNumbers}
          retrieveData={retrieveData}
        />
      ) : null}
      {selectedMed == 8 ? (
        <PilltrackAdd
          slotNumbers={slotNumbers}
          setSelectedMed={setSelectedMed}
          retrieveData={retrieveData}
        />
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  backButton: {
    fontWeight: "bold",
    color: "#FF6182",
    marginBottom: 12,
  },
  innerContainer: {
    width: "85%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 16,
    marginBottom: 64,
  },
  subtitle: {
    fontSize: 20,
    fontFamily: "Manrope_400Regular",
    color: "#453D3F",
  },
  card: {
    backgroundColor: "white",
    borderRadius: 12,
    shadowColor: "#666699",
    shadowOpacity: 0.3,
    shadowRadius: 8,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 24,
    elevation: 4,
  },
  pilltrack: {
    backgroundColor: "white",
    borderRadius: 16,
    shadowColor: "grey",
    shadowOpacity: 0.5,
    shadowRadius: 8,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 16,
  },
  pillbottle: {
    backgroundColor: "#E85B81",
    borderRadius: 1000,
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    fontFamily: "Manrope_700Bold",
    color: "#453D3F",
    fontWeight: "bold",
  },
  subtext: {
    fontSize: 14,
    color: "#453D3F",
    fontFamily: "Manrope_700Bold",
  },
  arrow: {
    position: "absolute",
    right: 0,
  },
  label: {
    borderRadius: 100,
    padding: 8,
    paddingLeft: 12,
    paddingRight: 12,
    alignItems: "center",
    marginRight: 6,
  },
  textInput: {
    backgroundColor: "#F5F5F5",
    paddingLeft: 12,
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 8,
    fontSize: 24,
    fontFamily: "Manrope_700Bold",
    color: "#808080",
    marginTop: 24,
  },
  checkboxContainer: {
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  checkbox: {
    padding: 8,
    borderColor: "#E85B81",
    height: 32,
    width: 32,
    color: "#E85B81",
  },
  primaryButton: {
    backgroundColor: "#E85B81",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 8,
  },
  primaryButtonText: {
    color: "white",
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Manrope_400Regular",
  },
  secondaryButton: {
    backgroundColor: "white",
    borderColor: "#E85B81",
    borderWidth: 1,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 8,
  },
  secondaryButtonText: {
    color: "#E85B81",
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Manrope_400Regular",
  },
  pillbottleText: {
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Manrope_700Bold",
  },
  textContainer: {
    marginTop: 50,
    textAlign: "center",
    width: "85%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  verticalAlign: {
    justifyContent: "center",
    flex: 1,
    flexDirection: "column",
  },
  instructions: {
    fontSize: 24,
    fontFamily: "Manrope_400Regular",
    color: "#808080",
  },
});
