import React from "react";
import { useState } from "react";
import {
  Platform,
  View,
  Button,
  Text,
  Image,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Alert,
  ScrollView,
  KeyboardAvoidingView,
  Dimensions,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

import AthelasLogo from "../../assets/logo.png";
import { confirmLogin } from "./actions";
import DoctorFlatDesign from "./assets/doctors-flat-design.svg";
import DoctorFlatDesignPNG from "./assets/doctors-flat-design.png";

export function LoginConfirm({ route, navigation }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [sixDigitCode, setSixDigitCode] = useState("");
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);
  const { phoneNumber } = route.params;

  const onConfirmClicked = (phoneNumber: string, sixDigitCode: string) => {
    const phone = phoneNumber
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/ - /g, "");
    setErrorMessage("");
    confirmLogin(phone, sixDigitCode)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          setErrorMessage(
            "Incorrect authentication code. Please wait 60 seconds before attempting another login."
          );
          setDisableConfirmButton(true);
          setSixDigitCode("");
          setTimeout(() => {
            setErrorMessage("");
            setDisableConfirmButton(false);
          }, 60000);
        }
      })
      .then((j) => {
        if (!j) {
          return;
        }
        const { token, patient_id } = j;
        AsyncStorage.setItem("token", token);
        AsyncStorage.setItem("patient_id", JSON.stringify(patient_id));
        navigation.navigate("Main");
      })
      .catch((err) => {
        setErrorMessage(err);
      });
  };

  const LoginView = (
    <ScrollView>
      <View
        style={{
          flex: 1,
          alignItems: "center",
          backgroundColor: "white",
          height: Dimensions.get("window").height * 1.25,
          width: Dimensions.get("window").width,
        }}
      >
        <Image
          source={AthelasLogo}
          style={styles.athelasLogo}
          resizeMode="contain"
        />
        <Image
          source={DoctorFlatDesignPNG}
          style={styles.highlightImage}
          resizeMode="contain"
        />
        <Text style={styles.enterCodeText}>
          We texted you a 6 digit code to your phone number{" "}
          {phoneNumber.replace(/ /g, "")}. Enter the code below to log in.
        </Text>

        <Text style={styles.enterCodeText}>
          The text message can take up to 2 minutes to arrive.
        </Text>

        <TextInput
          style={styles.phoneNumberInput}
          onChangeText={(text) => setSixDigitCode(text.toUpperCase())}
          value={sixDigitCode}
          placeholder="ex. A5BD2X"
          placeholderTextColor="#CFCFCF"
          textContentType="oneTimeCode"
          keyboardType="numeric"
        />

        <Text style={styles.errorMessage}>{errorMessage}</Text>

        <TouchableOpacity
          onPress={() => {
            if (disableConfirmButton) {
              return;
            }
            return onConfirmClicked(phoneNumber, sixDigitCode);
          }}
        >
          <View
            style={
              disableConfirmButton
                ? styles.loginButtonContainerDisabled
                : styles.loginButtonContainer
            }
          >
            <Text style={styles.loginButtonText}>CONFIRM LOG IN</Text>
          </View>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      {Platform.OS === "android" ? (
        LoginView
      ) : (
        <KeyboardAvoidingView behavior="padding">
          {LoginView}
        </KeyboardAvoidingView>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  loginButtonContainer: {
    backgroundColor: "#FE5E85",
    padding: 12,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 16,
  },
  loginButtonContainerDisabled: {
    backgroundColor: "#888",
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 16,
  },
  loginButtonText: { color: "white", fontWeight: "bold" },
  phoneNumberInput: {
    width: "100%",
    maxWidth: 300,
    marginTop: 8,
    borderWidth: 3,
    borderColor: "#E5E5E5",
    borderStyle: "solid",
    backgroundColor: "#FDFDFD",
    textAlign: "center",
    padding: 16,
    borderRadius: 16,
    marginBottom: 16,
    fontSize: 18,
  },
  enterCodeText: {
    fontSize: 16,
    color: "#565F76",
    marginTop: 16,
    maxWidth: 300,
    textAlign: "center",
  },
  subtitle: { fontSize: 16, color: "#565F76" },
  header: {
    fontWeight: "bold",
    fontSize: 20,
    marginBottom: 8,
    color: "#565F76",
  },
  highlightImage: { width: 160, height: 120 },
  jumbotron: {
    padding: 16,
    borderRadius: 16,
    backgroundColor: "#EAEAEA",
    marginTop: 24,
    maxWidth: 300,
  },
  athelasLogo: {
    width: 160,
    height: 100,
    marginTop: 64,
  },
  errorMessage: {
    fontSize: 14,
    color: "red",
    maxWidth: 300,
    marginBottom: 8,
    textAlign: "center",
  },
});
