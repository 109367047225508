import { API_BASE_URL } from "../../../constants";
  
  export function updatePatientDeviceMapping(notificationToken: String, deviceID: String, patient_id: Number) {
    const body = {
      notification_token: notificationToken,
      device_id: deviceID,
      patient_id: patient_id
    };
  
    return fetch(
      `${API_BASE_URL}/update_patient_device_mapping`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
  }

  export function removePatientIdFromDevice(patient_id: Number) {
    const body = {
      patient_id: patient_id
    };

    return fetch(
      `${API_BASE_URL}/remove_patient_id_from_device`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
  }