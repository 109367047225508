import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useState } from "react";
import {
  Text,
  StyleSheet,
  View,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { trackEvent } from "../../../utils/tracking";
import { addResult } from "./actions";

type PropsType = {
  test_type?:
    | "CARDIAC_CARE"
    | "HEART_RATE"
    | "BLOOD_PRESSURE"
    | "WEIGHT"
    | "GLUCOSE"
    | "PULSE_OXIMETRY";
  setMode: any;
  fetchResults: any;
};

const DATA_INPUT_MAP = {
  CARDIAC_CARE: ["cardiac_care_weight_lbs"],
  HEART_RATE: ["bpm"],
  BLOOD_PRESSURE: ["systolic", "diastolic"],
  WEIGHT: ["weight"],
  GLUCOSE: ["glucose_value"],
  PULSE_OXIMETRY: ["spo2"],
};

export function AddNewTestResultForm(props: PropsType) {
  const [argData, setArgData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  if (
    props.test_type &&
    !Object.keys(DATA_INPUT_MAP).includes(props.test_type)
  ) {
    return <Text>Invalid Test Type</Text>;
  }

  let args = props.test_type ? DATA_INPUT_MAP[props.test_type] : [];

  const uploadResults = (argData) => {
    AsyncStorage.getItem("token").then((token) => {
      if (!token || !props.test_type) {
        return;
      }
      addResult(token, argData, props.test_type).then((res) => {
        props.setMode("history");
        props.fetchResults();
      });
    });
  };

  return (
    <View style={styles.container}>
      <Text style={styles.inactiveLabel}>TEST TYPE</Text>
      <TextInput
        style={styles.inactiveTextInput}
        value={props.test_type?.replace("_", " ").toUpperCase()}
        editable={false}
      />

      {args.map((arg) => {
        let argTitle = arg.toUpperCase().replace(/_/g, " ");
        if (argTitle === "CARDIAC CARE WEIGHT LBS") {
          argTitle = "TOTAL CARE WEIGHT (LBS)";
        }

        return (
          <>
            <Text style={styles.label}>{argTitle}</Text>
            <TextInput
              style={styles.textInput}
              value={argData[arg]}
              onChange={(e) => {
                const text = e.nativeEvent.text;
                argData[arg] = parseFloat(text);
                setArgData(argData);
              }}
            ></TextInput>
          </>
        );
      })}
      <Text style={styles.alert}>{errorMessage}</Text>
      <TouchableOpacity
        onPress={() => {
          trackEvent("view", "uploaded_result_data");
          uploadResults(argData);
        }}
      >
        <View style={styles.submitButtonContainer}>
          <Text style={styles.submitButtonText}>ADD TEST RESULT</Text>
        </View>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => {
          props.setMode("history");
        }}
      >
        <View style={styles.cancelbuttonContainer}>
          <Text style={styles.submitButtonText}>CANCEL</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
    paddingTop: 0,
  },
  textInput: {
    borderWidth: 2,
    borderColor: "#FE5E85",
    borderStyle: "solid",
    borderRadius: 4,
    padding: 8,
    color: "#FE5E85",
  },
  label: {
    color: "#FE5E85",
    fontSize: 13,
    marginBottom: 4,
    fontWeight: "bold",
    marginTop: 16,
  },
  inactiveTextInput: {
    borderWidth: 2,
    borderColor: "#888",
    borderStyle: "solid",
    borderRadius: 4,
    padding: 8,
    color: "#888",
  },
  inactiveLabel: {
    color: "#888",
    fontSize: 13,
    marginBottom: 4,
    fontWeight: "bold",
    marginTop: 16,
  },
  submitButtonContainer: {
    backgroundColor: "#FE5E85",
    padding: 8,
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 4,
    marginTop: 36,
  },
  submitButtonText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  alert: {
    textAlign: "center",
    color: "red",
    marginTop: 16,
  },
  cancelbuttonContainer: {
    backgroundColor: "#888",
    padding: 8,
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 4,
    marginTop: 36,
  },
});
