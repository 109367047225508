import AsyncStorage from "@react-native-async-storage/async-storage";
import {Platform} from 'react-native'
import { API_BASE_URL } from "../constants";

export function track(
  action: "view" | "click",
  patient_id: number,
  tag: string,
  info: any,
  persistent_cookie: string
) {
  const payload = {
    action: action,
    data: {
      patient_id: patient_id,
      persistent_cookie: persistent_cookie,
      info: info,
    },
    tag: tag,
    application: "patient_portal",
  };

  if (Platform.OS === 'web')  {
    if (window.location.href.includes("localhost")) {
      console.log("tracked event:");
      console.log(payload);
      return;
    }
  }

  return fetch(`https://api.athelas.com/tracking/track`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((res) => {
    console.log("tracked.");
  });
}

export const trackEvent = (action: "click" | "view", tag: string) => {
  AsyncStorage.getItem("patient_id").then((id: string) => {
    const patient_id = parseInt(id);

    AsyncStorage.getItem("persistent_cookie").then(
      (persistent_cookie: string) => {
        track(action, patient_id, tag, {}, persistent_cookie);
      }
    );
  });
};
