import React from "react";
import { Platform, View, Text, StyleSheet, TouchableOpacity } from "react-native";

type PropsType = {
  test_type: string;
  setMode: any;
};

export function AddNewTestResult(props: PropsType) {
  const addNewResult = () => {
    props.setMode && props.setMode(props.test_type);
  };

  return (
    <TouchableOpacity onPress={addNewResult}>
      <View style={styles.container}>
        <View style={styles.plus_container}>
          <Text style={styles.plus}>+</Text>
        </View>
        <Text style={styles.text}>ADD NEW TEST RESULT</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    marginRight: 24,
    marginLeft: 24,
    marginTop: 12,
    marginBottom: 12,
    flexDirection: "row",
    display: "flex",
  },
  plus_container: {
    backgroundColor: "#E4F6EB",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    height: 40,
    width: 40,
    borderRadius: 16,
  },
  plus: {
    color: "#1BB55C",
    fontSize: 32,
    fontWeight: "bold",
    marginTop: -6,
    textAlign: "center"
  },
  text: {
    color: "#1BB55C",
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 8,
    marginTop: 10,
  },
});
