import React from "react";
import { Dimensions } from "react-native";
import { LineChart } from "react-native-chart-kit";

export function AthelasLineChart(props) {
  return (
    <LineChart
      key={props.key}
      data={{
        labels: props.labels,
        datasets: props.datasets,
      }}
      width={Dimensions.get("window").width}
      height={180}
      yAxisInterval={1000}
      chartConfig={{
        backgroundColor: "white",
        backgroundGradientFrom: "white",
        backgroundGradientTo: "white",
        decimalPlaces: 1,
        color: (opacity = 1) => "#DB5671",
        labelColor: (opacity = 1) => `#B9C1D9`,
        style: {
          borderRadius: 16,
        },
        propsForDots: {
          r: "1",
          strokeWidth: "1",
          stroke: "#DB5671",
          fill: "white",
        },
        propsForLabels: {
          fontSize: 10,
        },
      }}
      bezier
      style={{
        marginVertical: 8,
        borderRadius: 16,
        marginLeft: 0,
        marginRight: 24,
      }}
    />
  );
}
