import { StatusBar } from "expo-status-bar";
import React, {useState} from "react";
import { StyleSheet, Text, View, Button, Platform, Alert } from "react-native";

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { LoginAttempt } from "./pages/Login/LoginAttempt";
import { LoginConfirm } from "./pages/Login/LoginConfirm";
import { Main } from "./pages/Main/Main";
import { track, trackEvent } from "./utils/tracking";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { isNumeric, makeID } from "./utils/utils";
import { getProfile } from "./pages/Main/Dashboard/actions";
import { logout } from "./utils/auth";
import { PatientConsent } from "./pages/Main/PatientConsent/PatientConsent";
import RNRestart from 'react-native-restart'; 

const Stack = createStackNavigator();

export default function App({navigation}) {
  function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
  }

  AsyncStorage.getItem("token").then((token) => {
    if (token) {
      getProfile(token).then((res) => {
        if (res.status !== 200) {
          console.log("res status != 200")
          console.log('token is invalid!!!');
          logout(navigation);
          Platform.OS === 'ios' || Platform.OS === 'android' ? useForceUpdate() : window.location.reload();
        }
      });
    }
  });


  AsyncStorage.getItem("persistent_cookie").then((cookie: string) => {
    if (!cookie) {
      AsyncStorage.setItem("persistent_cookie", makeID(100));
    }
  });

  if (Platform.OS === 'web') {
    trackEvent("view", "opened_patient_portal_web");
  } else if (Platform.OS === 'ios') {
    trackEvent("view", "opened_patient_portal_ios");
  } else if (Platform.OS === 'android') {
    trackEvent("view", "opened_patient_portal_android");
  }
  

  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Home">
        <Stack.Screen
          name="Login"
          component={LoginAttempt}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Confirm Login"
          component={LoginConfirm}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Main"
          component={Main}
          options={{ headerShown: false, gestureEnabled: false}
        }
        />
        <Stack.Screen
          name="Patient Consent"
          component= {PatientConsent}
          options={{ headerShown: true }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
