import { Platform, Image, StyleSheet, View, Text } from "react-native";
import React from "react";
import { LinearGradient } from 'expo-linear-gradient';
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

import AthelasLogo from "../../assets/logo.png";
import AthelasWhiteLogo from "../../assets/whiteIcon.png"

type PropsType = {
  title: string;
};

export function Header(props: PropsType) {
  return (
    <View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#DB5671"
        }}
      >
        <LinearGradient colors={['#DB5671', '#ED8E94']} style={styles.header}>
        <Image source = {AthelasWhiteLogo} style = {styles.headerLogo}></Image>
        <Text
          style={styles.headerText}
        >
         {props.title || "Athelas"}
        </Text>
        </LinearGradient>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row", 
    width : "100%",
    height: (Platform.OS === 'ios' || Platform.OS === 'android')  ? 100 : 75
  },

  headerLogo: {
    height: 25,
    width: 25,
    marginTop: Platform.OS === 'ios'  ? 55 : (Platform.OS === 'android' ? 52.5 :  25),
    marginLeft: 20,
    marginRight: 10,
    resizeMode: 'contain' 
  },

  headerText: {
    padding: 24,
    paddingLeft: 4,
    fontSize: 22,
    fontWeight: "bold",
    color: "white",
    width: 200,
    marginTop: Platform.OS === 'ios'  ? 30 : (Platform.OS === 'android' ? 25 :  "inherit"),
  },
  
  container: {
    width: "33%",
    height: 60,
    justifyContent: "center",
    marginTop: 24,
    alignItems: "center",
  }
});
