import { API_BASE_URL } from "../../../constants";

export function getResults(token: String) {
  return fetch(`${API_BASE_URL}/results?token=${token}`);
}

export function getRecentResults(token: String, count: number) {
  return fetch(`${API_BASE_URL}/recent_results?token=${token}&count=${count}`);
}

export function getResultDetails(token: String, result_id: number) {
  return fetch(`${API_BASE_URL}/result_detail?token=${token}&id=${result_id}`);
}

export function deleteResult(token: String, result_id: number) {
  const body = {
    token: token,
    result_id: result_id,
  };
  return fetch(`${API_BASE_URL}/delete_result`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function addResult(token: String, data: any, test_type: String) {
  const body = {
    token: token,
    result_data: data,
    test_type: test_type,
  };

  return fetch(`${API_BASE_URL}/add_result`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}
