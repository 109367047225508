import React from "react";
import { Text, TouchableOpacity } from "react-native";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { LinearGradient } from 'expo-linear-gradient';


type PropsType = {
  text: string;
  onPress: () => void;
};

export const AthelasButton = (props: PropsType) => {
  return (
    <LinearGradient colors={['#DB5671', '#ED8E94']} style = {{borderRadius: 15,}}>
    <TouchableOpacity
      style={{
        margin: 6,
        padding: 16,
        borderRadius: 8,
      }}
      onPress={props.onPress}
    >
      <Text
        style={{
          textAlign: "center",
          fontSize: 16,
          fontWeight: "bold",
          color: "white",
        }}
      >
        {props.text}
      </Text>
      
    </TouchableOpacity>
    </LinearGradient>
  );
};
